import { Button, Form, FormProps, Input, Select, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKycContext } from '../../../../../context/KycContext';
import { DocumentFormats, YES_NO } from '../../../../../utils/data';
import { getLocalStorageData } from '../../../../../utils/src/formatCuurency';
import { StepProps } from '../../../../../utils/types';
import { RecordRetention } from '../../../../../utils/verify_types';

export const StepTen: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setRecordRetention,
    setRefetch,
  } = useKycContext();

  const exist_record_retention_policy = Form.useWatch(
    'exist_record_retention_policy',
    form
  );

  const onFinish: FormProps<RecordRetention>['onFinish'] = (values) => {
    if (values) {
      const payload = {
        ...values,
        record_retention_period_in_months: parseInt(
          values.record_retention_period_in_months
        ),
      };
      setRecordRetention(payload);
      localStorage.setItem('RecordRetention', JSON.stringify(payload));
      setRefetch();
      setCounter('increment');
      navigate(`/developer-portal/kyc/internal_and_external_audit`, {});
      setIsSubmitting(false);
    }
  };

  const onFinishFailed: FormProps<RecordRetention>['onFinishFailed'] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const RecordRetention: any = getLocalStorageData('RecordRetention', {});

    if (RecordRetention) {
      form.setFieldsValue({
        exist_record_retention_policy:
          RecordRetention?.exist_record_retention_policy,
        record_retention_policy_description:
          RecordRetention?.record_retention_policy_description,
        record_retention_period_in_months:
          RecordRetention?.record_retention_period_in_months,
        record_type: RecordRetention?.record_type,
      });
    }
  }, []);

  return (
    <Form
      name='RecordRetention'
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      layout='vertical'
    >
      <div className='grid grid-cols-2 gap-x-8 gap-y-7 pb-2 w-full'>
        <Form.Item<RecordRetention>
          name='exist_record_retention_policy'
          label='Is there a records retention policy?'
          rules={[
            {
              required: true,
              message: 'Please selet an option',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...YES_NO]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <Form.Item<RecordRetention>
          name='record_retention_policy_description'
          label='If so, please describe it.'
          rules={[
            {
              required: exist_record_retention_policy === true,
              message: 'Please describe your policy',
            },
          ]}
        >
          <TextArea rows={8} placeholder={''} className='text-area w-full' />
        </Form.Item>
      </div>

      <Form.Item<RecordRetention>
        name='record_retention_period_in_months'
        label='With regards to record retention, how long are customer identification, transactions, suspicious activity reports, etc. maintained?'
        className='w-1/2'
        rules={[
          {
            required: true,
            message: 'Please input your record retention period',
          },
          {
            pattern: /^[0-9,]+$/,
            message:
              'Please enter only numbers, alphabets and special characters are not allowed',
          },
        ]}
      >
        <Input size='large' />
      </Form.Item>

      <div className='grid grid-cols-2 gap-x-8 gap-y-7 pb-2 w-full'>
        <Form.Item<RecordRetention>
          name='record_type'
          label='In what format are records maintained (paper or electronic)?'
          rules={[
            {
              required: true,
              message: 'Please select a record format',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...DocumentFormats]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        {/* <Form.Item
          name="DirectorsNationality"
          label="How long does it take to retrieve a document dating back five years?"
          rules={[
            {
              required: true,
              message: "Please input your",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[
              { label: "No", value: "NO" },
              { label: "Yes", value: "YES" },
            ]}
            size="large"
            className="text-left"
          />
        </Form.Item> */}
      </div>

      <Space
        size={'middle'}
        align='baseline'
        style={{ float: 'left' }}
        className='mt-10'
      >
        <Form.Item>
          <Button
            type='default'
            htmlType='button'
            className='bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white'
            style={{ height: '56px', fontWeight: 'bold' }}
            onClick={() =>
              navigate(`/developer-portal/kyc/sanction_screening`, {})
            }
          >
            Back
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            size='small'
            className='bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation'
            style={{
              height: '56px',
              fontWeight: 'bold',
            }}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
