import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Footer } from './component/Footer';
import { Header } from './component/Header';
import AgentSideBar from './component/authenticated/AgentSideBar';
import { AuthHeader } from './component/authenticated/AuthHeader';
type Props = {
  children?: any;
};

const AgentLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === '') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location]);

  const [dashboardSidebar, setDashboardSidebar] = useState<boolean>(false);

  function showMenu() {
    setDashboardSidebar(!dashboardSidebar);
  }

  return (
    <div className='mainDashboard overflow-hidden'>
      <header>
        <AuthHeader showMenu={showMenu} dashboardSidebar={dashboardSidebar} />
      </header>

      <section className={'sideBar-Outlet'}>
        <div className={'dashboardSidebar'}>
          <AgentSideBar
            dashboardSidebar={dashboardSidebar}
            showMenu={showMenu}
          />
        </div>
        <div className='main-containe'>
          <div className='content'>
            <Outlet />
          </div>
        </div>
      </section>
    </div>
  );
};

export { AgentLayout };
