import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useGetOnboardingProfile } from '../../../../../hooks/profile/profile.hook';
import { IOnboardingProfile } from '../../../../../utils/types';
//import { getStoredUser } from "../../pages/auth/user-storage";

const { SubMenu } = Menu;
interface AgentSideBarProps {
  dashboardSidebar: Boolean;
  showMenu: () => void;
}

const hasRoleOAGFAdmin = (roles: any) => {
  return roles?.some((role: { id: string }) => role?.id === 'ROLE_OAGF_ADMIN');
};

const AgentSideBar: React.FC<AgentSideBarProps> = ({
  dashboardSidebar,
  showMenu,
}) => {
  const [activeMenu, setActiveMenu] = useState('');

  //const authed = getStoredUser();

  const navigate = useNavigate();
  const location = useLocation();

  const generalStyle: any = {
    display: 'grid',
    gap: '1rem',
    background: '#F2F2F2',

    height: 50,
  };

  const { onboarding, isFetching } = useGetOnboardingProfile();

  const onboardingData: IOnboardingProfile = onboarding?.data?.data;
  const verified = onboardingData?.is_verified;

  const subMenuStyle: any = {
    background: '#FEF5D7',
    borderRadius: '4px',
    // fontFamily: "Poppins",
    // fontStyle: "normal",
    // fontWeight: 500,
    // fontSize: "14px",
    // lineHight: "21px",
    // color: "#061B01",
  };

  const currentPath = location.pathname;

  const getParentMenuKey = (path: string) => {
    if (path.startsWith('/developer-portal')) {
      return 'developer-portal';
    } else if (path.startsWith('/services')) {
      return 'services';
    } else {
      return 'dashboard'; // default parent menu key
    }
  };

  // Determine the parent menu key for the current route
  const currentParentMenuKey = getParentMenuKey(currentPath);

  // Set the active key and default selected keys
  const [activeKey, setActiveKey] = useState<string>(currentPath);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([
    currentParentMenuKey,
  ]);

  const handleClick = (e: any) => {
    if (e.length !== 0) {
      setActiveKey(e);
    }
  };

  useEffect(() => {
    // Set the default selected keys based on the parent menu key
    setDefaultSelectedKeys([currentParentMenuKey]);
  }, [currentParentMenuKey]);

  const closeSidebar = () => {
    if (dashboardSidebar) {
      showMenu();
    }
  };

  const Padlock = () => {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.7258 7.83597V6.04513C13.7 3.94596 11.9767 2.26596 9.87833 2.2918C7.8225 2.31763 6.16 3.97263 6.125 6.02847V7.83597'
          stroke='#A4A4A6'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.92521 11.7966V13.6475'
          stroke='#A4A4A6'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.92532 7.35327C5.13783 7.35327 3.54199 8.65994 3.54199 12.5791C3.54199 16.4991 5.13783 17.8058 9.92532 17.8058C14.7128 17.8058 16.3095 16.4991 16.3095 12.5791C16.3095 8.65994 14.7128 7.35327 9.92532 7.35327Z'
          stroke='#A4A4A6'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    );
  };

  return (
    <div
      className={` sidebarMain ${
        dashboardSidebar ? ' sidebarMainActivated' : ''
      }`}
    >
      <nav className=''>
        {isFetching ? (
          <Menu
            mode='inline'
            // style={generalStyle}
            // defaultOpenKeys={[activeKey]}
            onOpenChange={handleClick}
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={[currentParentMenuKey]}
            className='sidebarHeading'
          >
            <Menu.Item
              key='/developer-portal/home'
              className={`menu-item 
              ${currentPath === '/developer-portal/home' ? 'text-primary' : ''}
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/home' ? '#FBE9B2' : '',
                borderRadius:
                  currentPath === '/developer-portal/home' ? '4px' : '',
              }}
            >
              <Link
                className='menu-link-text'
                onClick={closeSidebar}
                to='/developer-portal/home'
              >
                <span style={{ marginRight: '8px' }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M9.15859 11.62C9.12859 11.62 9.10859 11.62 9.07859 11.62C9.02859 11.61 8.95859 11.61 8.89859 11.62C5.99859 11.53 3.80859 9.25 3.80859 6.44C3.80859 3.58 6.13859 1.25 8.99859 1.25C11.8586 1.25 14.1886 3.58 14.1886 6.44C14.1786 9.25 11.9786 11.53 9.18859 11.62C9.17859 11.62 9.16859 11.62 9.15859 11.62ZM8.99859 2.75C6.96859 2.75 5.30859 4.41 5.30859 6.44C5.30859 8.44 6.86859 10.05 8.85859 10.12C8.91859 10.11 9.04859 10.11 9.17859 10.12C11.1386 10.03 12.6786 8.42 12.6886 6.44C12.6886 4.41 11.0286 2.75 8.99859 2.75Z'
                      fill='#856504'
                    />
                    <path
                      d='M16.5384 11.75C16.5084 11.75 16.4784 11.75 16.4484 11.74C16.0384 11.78 15.6184 11.49 15.5784 11.08C15.5384 10.67 15.7884 10.3 16.1984 10.25C16.3184 10.24 16.4484 10.24 16.5584 10.24C18.0184 10.16 19.1584 8.96 19.1584 7.49C19.1584 5.97 17.9284 4.74 16.4084 4.74C15.9984 4.75 15.6584 4.41 15.6584 4C15.6584 3.59 15.9984 3.25 16.4084 3.25C18.7484 3.25 20.6584 5.16 20.6584 7.5C20.6584 9.8 18.8584 11.66 16.5684 11.75C16.5584 11.75 16.5484 11.75 16.5384 11.75Z'
                      fill='#856504'
                    />
                    <path
                      d='M9.17156 22.55C7.21156 22.55 5.24156 22.05 3.75156 21.05C2.36156 20.13 1.60156 18.87 1.60156 17.5C1.60156 16.13 2.36156 14.86 3.75156 13.93C6.75156 11.94 11.6116 11.94 14.5916 13.93C15.9716 14.85 16.7416 16.11 16.7416 17.48C16.7416 18.85 15.9816 20.12 14.5916 21.05C13.0916 22.05 11.1316 22.55 9.17156 22.55ZM4.58156 15.19C3.62156 15.83 3.10156 16.65 3.10156 17.51C3.10156 18.36 3.63156 19.18 4.58156 19.81C7.07156 21.48 11.2716 21.48 13.7616 19.81C14.7216 19.17 15.2416 18.35 15.2416 17.49C15.2416 16.64 14.7116 15.82 13.7616 15.19C11.2716 13.53 7.07156 13.53 4.58156 15.19Z'
                      fill='#856504'
                    />
                    <path
                      d='M18.3382 20.75C17.9882 20.75 17.6782 20.51 17.6082 20.15C17.5282 19.74 17.7882 19.35 18.1882 19.26C18.8182 19.13 19.3982 18.88 19.8482 18.53C20.4182 18.1 20.7282 17.56 20.7282 16.99C20.7282 16.42 20.4182 15.88 19.8582 15.46C19.4182 15.12 18.8682 14.88 18.2182 14.73C17.8182 14.64 17.5582 14.24 17.6482 13.83C17.7382 13.43 18.1382 13.17 18.5482 13.26C19.4082 13.45 20.1582 13.79 20.7682 14.26C21.6982 14.96 22.2282 15.95 22.2282 16.99C22.2282 18.03 21.6882 19.02 20.7582 19.73C20.1382 20.21 19.3582 20.56 18.4982 20.73C18.4382 20.75 18.3882 20.75 18.3382 20.75Z'
                      fill='#856504'
                    />
                  </svg>
                </span>
                Developers Portal
              </Link>
            </Menu.Item>

            <Menu.Item
              key='/developer-portal/prefund'
              className={`menu-item 
              ${
                currentPath === '/developer-portal/prefund'
                  ? 'text-primary'
                  : ''
              }
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/prefund' ? '#FBE9B2' : '',
                borderRadius:
                  currentPath === '/developer-portal/prefund' ? '4px' : '',
              }}
            >
              <p
                className='menu-link-text flex flex-row items-center'
                onClick={closeSidebar}
              >
                <span style={{ marginRight: '8px' }}>
                  <Padlock />
                </span>
                <span>Pre-Fund</span>
              </p>
            </Menu.Item>

            <Menu.Item
              key='/developer-portal/order'
              className={`menu-item 
              ${currentPath === '/developer-portal/order' ? 'text-primary' : ''}
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/order' ||
                  currentPath.includes('order')
                    ? '#FBE9B2'
                    : '',
                borderRadius:
                  currentPath === '/developer-portal/order' ||
                  currentPath.includes('order')
                    ? '4px'
                    : '',
              }}
            >
              <span
                className='menu-link-text flex flex-row items-center'
                onClick={closeSidebar}
              >
                <span style={{ marginRight: '8px' }}>
                  <Padlock />
                </span>
                <span>Order</span>
              </span>
            </Menu.Item>

            <Menu.Item
              key='/developer-portal/funds'
              className={`menu-item 
              ${currentPath === '/developer-portal/funds' ? 'text-primary' : ''}
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/funds' ? '#FBE9B2' : '',
                borderRadius:
                  currentPath === '/developer-portal/funds' ? '4px' : '',
              }}
            >
              <span
                className='menu-link-text flex flex-row items-center'
                onClick={closeSidebar}
              >
                <span style={{ marginRight: '8px' }}>
                  <Padlock />
                </span>
                <span>Funds</span>
              </span>
            </Menu.Item>

            <Menu.Item
              key={'/developer-portal/settings/account'}
              className={`menu-item 
              ${
                currentPath === '/developer-portal/settings/account'
                  ? 'text-primary bg-BagYellow'
                  : ''
              } mt-12
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/settings/account'
                    ? '#FBE9B2'
                    : '',
                borderRadius:
                  currentPath === '/developer-portal/settings/account'
                    ? '4px'
                    : '',
              }}
            >
              <Link
                className='menu-link-text'
                onClick={closeSidebar}
                to='/developer-portal/settings/account'
              >
                <span style={{ marginRight: '8px' }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z'
                      fill='#9D9D9D'
                    />
                    <path
                      d='M15.5 19.25C13.98 19.25 12.75 18.02 12.75 16.5V7.5C12.75 5.98 13.98 4.75 15.5 4.75C17.02 4.75 18.25 5.98 18.25 7.5V16.5C18.25 18.02 17.02 19.25 15.5 19.25ZM15.5 6.25C14.81 6.25 14.25 6.81 14.25 7.5V16.5C14.25 17.19 14.81 17.75 15.5 17.75C16.19 17.75 16.75 17.19 16.75 16.5V7.5C16.75 6.81 16.19 6.25 15.5 6.25Z'
                      fill='#9D9D9D'
                    />
                    <path
                      d='M8.5 19.25C6.98 19.25 5.75 18.02 5.75 16.5V13C5.75 11.48 6.98 10.25 8.5 10.25C10.02 10.25 11.25 11.48 11.25 13V16.5C11.25 18.02 10.02 19.25 8.5 19.25ZM8.5 11.75C7.81 11.75 7.25 12.31 7.25 13V16.5C7.25 17.19 7.81 17.75 8.5 17.75C9.19 17.75 9.75 17.19 9.75 16.5V13C9.75 12.31 9.19 11.75 8.5 11.75Z'
                      fill='#9D9D9D'
                    />
                  </svg>{' '}
                </span>
                Profile Settings
              </Link>
            </Menu.Item>
          </Menu>
        ) : (
          <Menu
            mode='inline'
            // style={generalStyle}
            // defaultOpenKeys={[activeKey]}
            onOpenChange={handleClick}
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={[currentParentMenuKey]}
            className='sidebarHeading'
          >
            <Menu.Item
              key='/developer-portal/home'
              className={`menu-item 
              ${currentPath === '/developer-portal/home' ? 'text-primary' : ''}
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/home' ? '#FBE9B2' : '',
                borderRadius:
                  currentPath === '/developer-portal/home' ? '4px' : '',
              }}
            >
              <Link
                className='menu-link-text'
                onClick={closeSidebar}
                to='/developer-portal/home'
              >
                <span style={{ marginRight: '8px' }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M9.15859 11.62C9.12859 11.62 9.10859 11.62 9.07859 11.62C9.02859 11.61 8.95859 11.61 8.89859 11.62C5.99859 11.53 3.80859 9.25 3.80859 6.44C3.80859 3.58 6.13859 1.25 8.99859 1.25C11.8586 1.25 14.1886 3.58 14.1886 6.44C14.1786 9.25 11.9786 11.53 9.18859 11.62C9.17859 11.62 9.16859 11.62 9.15859 11.62ZM8.99859 2.75C6.96859 2.75 5.30859 4.41 5.30859 6.44C5.30859 8.44 6.86859 10.05 8.85859 10.12C8.91859 10.11 9.04859 10.11 9.17859 10.12C11.1386 10.03 12.6786 8.42 12.6886 6.44C12.6886 4.41 11.0286 2.75 8.99859 2.75Z'
                      fill='#856504'
                    />
                    <path
                      d='M16.5384 11.75C16.5084 11.75 16.4784 11.75 16.4484 11.74C16.0384 11.78 15.6184 11.49 15.5784 11.08C15.5384 10.67 15.7884 10.3 16.1984 10.25C16.3184 10.24 16.4484 10.24 16.5584 10.24C18.0184 10.16 19.1584 8.96 19.1584 7.49C19.1584 5.97 17.9284 4.74 16.4084 4.74C15.9984 4.75 15.6584 4.41 15.6584 4C15.6584 3.59 15.9984 3.25 16.4084 3.25C18.7484 3.25 20.6584 5.16 20.6584 7.5C20.6584 9.8 18.8584 11.66 16.5684 11.75C16.5584 11.75 16.5484 11.75 16.5384 11.75Z'
                      fill='#856504'
                    />
                    <path
                      d='M9.17156 22.55C7.21156 22.55 5.24156 22.05 3.75156 21.05C2.36156 20.13 1.60156 18.87 1.60156 17.5C1.60156 16.13 2.36156 14.86 3.75156 13.93C6.75156 11.94 11.6116 11.94 14.5916 13.93C15.9716 14.85 16.7416 16.11 16.7416 17.48C16.7416 18.85 15.9816 20.12 14.5916 21.05C13.0916 22.05 11.1316 22.55 9.17156 22.55ZM4.58156 15.19C3.62156 15.83 3.10156 16.65 3.10156 17.51C3.10156 18.36 3.63156 19.18 4.58156 19.81C7.07156 21.48 11.2716 21.48 13.7616 19.81C14.7216 19.17 15.2416 18.35 15.2416 17.49C15.2416 16.64 14.7116 15.82 13.7616 15.19C11.2716 13.53 7.07156 13.53 4.58156 15.19Z'
                      fill='#856504'
                    />
                    <path
                      d='M18.3382 20.75C17.9882 20.75 17.6782 20.51 17.6082 20.15C17.5282 19.74 17.7882 19.35 18.1882 19.26C18.8182 19.13 19.3982 18.88 19.8482 18.53C20.4182 18.1 20.7282 17.56 20.7282 16.99C20.7282 16.42 20.4182 15.88 19.8582 15.46C19.4182 15.12 18.8682 14.88 18.2182 14.73C17.8182 14.64 17.5582 14.24 17.6482 13.83C17.7382 13.43 18.1382 13.17 18.5482 13.26C19.4082 13.45 20.1582 13.79 20.7682 14.26C21.6982 14.96 22.2282 15.95 22.2282 16.99C22.2282 18.03 21.6882 19.02 20.7582 19.73C20.1382 20.21 19.3582 20.56 18.4982 20.73C18.4382 20.75 18.3882 20.75 18.3382 20.75Z'
                      fill='#856504'
                    />
                  </svg>
                </span>
                Developers Portal
              </Link>
            </Menu.Item>

            <Menu.Item
              key='/developer-portal/prefund'
              className={`menu-item 
              ${
                currentPath === '/developer-portal/prefund'
                  ? 'text-primary'
                  : ''
              }
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/prefund' ? '#FBE9B2' : '',
                borderRadius:
                  currentPath === '/developer-portal/prefund' ? '4px' : '',
              }}
            >
              {verified === false ? (
                <p
                  className='menu-link-text flex flex-row items-center'
                  onClick={closeSidebar}
                >
                  <span style={{ marginRight: '8px' }}>
                    <Padlock />
                  </span>
                  <span>Pre-Fund</span>
                </p>
              ) : (
                <Link
                  className='menu-link-text'
                  onClick={closeSidebar}
                  to={'/developer-portal/prefund'}
                >
                  <span style={{ marginRight: '8px' }}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        d='M22 6.31055H12C11.59 6.31055 11.25 5.97055 11.25 5.56055C11.25 5.15055 11.59 4.81055 12 4.81055H22C22.41 4.81055 22.75 5.15055 22.75 5.56055C22.75 5.98055 22.41 6.31055 22 6.31055Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M19.7778 11.25H14.2278C12.0378 11.25 11.2578 10.48 11.2578 8.3V4.2C11.2578 2.02 12.0378 1.25 14.2278 1.25H19.7778C21.9678 1.25 22.7478 2.02 22.7478 4.2V8.31C22.7478 10.48 21.9678 11.25 19.7778 11.25ZM14.2178 2.75C12.8578 2.75 12.7478 2.86 12.7478 4.2V8.31C12.7478 9.65 12.8578 9.76 14.2178 9.76H19.7678C21.1278 9.76 21.2378 9.65 21.2378 8.31V4.2C21.2378 2.86 21.1278 2.75 19.7678 2.75H14.2178Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M12 17.8105H2C1.59 17.8105 1.25 17.4705 1.25 17.0605C1.25 16.6505 1.59 16.3105 2 16.3105H12C12.41 16.3105 12.75 16.6505 12.75 17.0605C12.75 17.4805 12.41 17.8105 12 17.8105Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M9.7778 22.75H4.22781C2.03781 22.75 1.25781 21.98 1.25781 19.8V15.7C1.25781 13.52 2.03781 12.75 4.22781 12.75H9.7778C11.9678 12.75 12.7478 13.52 12.7478 15.7V19.81C12.7478 21.98 11.9678 22.75 9.7778 22.75ZM4.2178 14.25C2.8578 14.25 2.7478 14.36 2.7478 15.7V19.81C2.7478 21.15 2.8578 21.26 4.2178 21.26H9.76779C11.1278 21.26 11.2378 21.15 11.2378 19.81V15.7C11.2378 14.36 11.1278 14.25 9.76779 14.25H4.2178Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M15.0002 22.7502C14.7302 22.7502 14.4802 22.6002 14.3502 22.3702C14.2202 22.1302 14.2202 21.8502 14.3602 21.6102L15.4102 19.8602C15.6202 19.5102 16.0802 19.3902 16.4402 19.6002C16.8002 19.8102 16.9102 20.2702 16.7002 20.6302L16.4302 21.0802C19.1902 20.4302 21.2602 17.9502 21.2602 14.9902C21.2602 14.5802 21.6002 14.2402 22.0102 14.2402C22.4202 14.2402 22.7602 14.5802 22.7602 14.9902C22.7502 19.2702 19.2702 22.7502 15.0002 22.7502Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M2 9.75C1.59 9.75 1.25 9.41 1.25 9C1.25 4.73 4.73 1.25 9 1.25C9.27 1.25 9.51999 1.4 9.64999 1.63C9.77999 1.87 9.78001 2.15 9.64001 2.39L8.59 4.14C8.38 4.49 7.92 4.61 7.56 4.4C7.2 4.19 7.08999 3.73 7.29999 3.37L7.57001 2.92C4.81001 3.57 2.73999 6.05 2.73999 9.01C2.74999 9.41 2.41 9.75 2 9.75Z'
                        fill='#9D9D9D'
                      />
                    </svg>
                  </span>
                  Pre-Fund
                </Link>
              )}
            </Menu.Item>

            <Menu.Item
              key='/developer-portal/order'
              className={`menu-item 
              ${currentPath === '/developer-portal/order' ? 'text-primary' : ''}
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/order' ||
                  currentPath.includes('order')
                    ? '#FBE9B2'
                    : '',
                borderRadius:
                  currentPath === '/developer-portal/order' ||
                  currentPath.includes('order')
                    ? '4px'
                    : '',
              }}
            >
              {verified === false ? (
                <span
                  className='menu-link-text flex flex-row items-center'
                  onClick={closeSidebar}
                >
                  <span style={{ marginRight: '8px' }}>
                    <Padlock />
                  </span>
                  <span>Order</span>
                </span>
              ) : (
                <Link
                  className='menu-link-text'
                  onClick={closeSidebar}
                  to={'/developer-portal/order'}
                >
                  <span style={{ marginRight: '8px' }}>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.17371 22.7496C4.79371 22.7496 2.84375 21.0196 2.84375 18.8896V16.8496C2.84375 16.4396 3.18375 16.0996 3.59375 16.0996C4.00375 16.0996 4.34375 16.4396 4.34375 16.8496C4.34375 18.0996 5.55371 19.0396 7.17371 19.0396C8.79371 19.0396 10.0037 18.0996 10.0037 16.8496C10.0037 16.4396 10.3437 16.0996 10.7537 16.0996C11.1637 16.0996 11.5037 16.4396 11.5037 16.8496V18.8896C11.5037 21.0196 9.56371 22.7496 7.17371 22.7496ZM4.6037 19.8696C5.0437 20.6896 6.03371 21.2496 7.17371 21.2496C8.31371 21.2496 9.30371 20.6796 9.74371 19.8696C9.03371 20.2996 8.15371 20.5496 7.17371 20.5496C6.19371 20.5496 5.3137 20.2996 4.6037 19.8696Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M7.17371 17.7994C5.53371 17.7994 4.06374 17.0494 3.33374 15.8594C3.01374 15.3394 2.84375 14.7294 2.84375 14.1094C2.84375 13.0594 3.30374 12.0794 4.14374 11.3494C5.76374 9.92943 8.55372 9.92948 10.1837 11.3395C11.0237 12.0795 11.4937 13.0594 11.4937 14.1094C11.4937 14.7294 11.3237 15.3394 11.0037 15.8594C10.2837 17.0494 8.81371 17.7994 7.17371 17.7994ZM7.17371 11.7495C6.39371 11.7495 5.67373 12.0094 5.13373 12.4794C4.62373 12.9194 4.34375 13.4994 4.34375 14.1094C4.34375 14.4594 4.43371 14.7795 4.61371 15.0795C5.07371 15.8395 6.05371 16.3095 7.17371 16.3095C8.29371 16.3095 9.27369 15.8395 9.72369 15.0895C9.90369 14.7995 9.99371 14.4695 9.99371 14.1195C9.99371 13.5095 9.71374 12.9294 9.20374 12.4794C8.67374 12.0094 7.95371 11.7495 7.17371 11.7495Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M7.17371 20.55C4.70371 20.55 2.84375 18.96 2.84375 16.86V14.11C2.84375 11.98 4.78371 10.25 7.17371 10.25C8.30371 10.25 9.38373 10.64 10.1937 11.34C11.0337 12.08 11.5037 13.06 11.5037 14.11V16.86C11.5037 18.96 9.64371 20.55 7.17371 20.55ZM7.17371 11.75C5.61371 11.75 4.34375 12.81 4.34375 14.11V16.86C4.34375 18.11 5.55371 19.05 7.17371 19.05C8.79371 19.05 10.0037 18.11 10.0037 16.86V14.11C10.0037 13.5 9.72375 12.92 9.21375 12.47C8.67375 12.01 7.95371 11.75 7.17371 11.75Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M19.0399 14.7992C17.5299 14.7992 16.2499 13.6792 16.1299 12.2392C16.0499 11.4092 16.3499 10.5992 16.9499 10.0092C17.4499 9.48922 18.1599 9.19922 18.9099 9.19922H20.9999C21.9899 9.22922 22.7499 10.0092 22.7499 10.9692V13.0292C22.7499 13.9892 21.9899 14.7692 21.0299 14.7992H19.0399ZM20.9699 10.6992H18.9199C18.5699 10.6992 18.2499 10.8292 18.0199 11.0692C17.7299 11.3492 17.5899 11.7292 17.6299 12.1092C17.6799 12.7692 18.3199 13.2992 19.0399 13.2992H20.9999C21.1299 13.2992 21.2499 13.1792 21.2499 13.0292V10.9692C21.2499 10.8192 21.1299 10.7092 20.9699 10.6992Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M16.0022 21.25H13.5022C13.0922 21.25 12.7522 20.91 12.7522 20.5C12.7522 20.09 13.0922 19.75 13.5022 19.75H16.0022C18.5822 19.75 20.2522 18.08 20.2522 15.5V14.8H19.0422C17.5322 14.8 16.2522 13.68 16.1322 12.24C16.0522 11.41 16.3522 10.6 16.9522 10.01C17.4522 9.49001 18.1622 9.20001 18.9122 9.20001H20.2422V8.5C20.2422 6.16 18.8722 4.54998 16.6522 4.28998C16.4122 4.24998 16.2022 4.25 15.9922 4.25H6.99219C6.75219 4.25 6.52218 4.26999 6.29218 4.29999C4.09218 4.57999 2.74219 6.18 2.74219 8.5V10.5C2.74219 10.91 2.40219 11.25 1.99219 11.25C1.58219 11.25 1.24219 10.91 1.24219 10.5V8.5C1.24219 5.42 3.14222 3.19001 6.09222 2.82001C6.36222 2.78001 6.67219 2.75 6.99219 2.75H15.9922C16.2322 2.75 16.5422 2.76 16.8622 2.81C19.8122 3.15 21.7422 5.39 21.7422 8.5V9.95001C21.7422 10.36 21.4022 10.7 20.9922 10.7H18.9122C18.5622 10.7 18.2422 10.83 18.0122 11.07C17.7222 11.35 17.5822 11.73 17.6222 12.11C17.6722 12.77 18.3122 13.3 19.0322 13.3H21.0022C21.4122 13.3 21.7522 13.64 21.7522 14.05V15.5C21.7522 18.94 19.4422 21.25 16.0022 21.25Z'
                        fill='#9D9D9D'
                      />
                    </svg>
                  </span>
                  Order
                </Link>
              )}
            </Menu.Item>

            <Menu.Item
              key='/developer-portal/funds'
              className={`menu-item 
              ${currentPath === '/developer-portal/funds' ? 'text-primary' : ''}
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/funds' ? '#FBE9B2' : '',
                borderRadius:
                  currentPath === '/developer-portal/funds' ? '4px' : '',
              }}
            >
              {verified === false ? (
                <span
                  className='menu-link-text flex flex-row items-center'
                  onClick={closeSidebar}
                >
                  <span style={{ marginRight: '8px' }}>
                    <Padlock />
                  </span>
                  <span>Funds</span>
                </span>
              ) : (
                <Link
                  className='menu-link-text'
                  onClick={closeSidebar}
                  to={'/developer-portal/funds'}
                >
                  <span style={{ marginRight: '8px' }}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M15.5 19.25C13.98 19.25 12.75 18.02 12.75 16.5V7.5C12.75 5.98 13.98 4.75 15.5 4.75C17.02 4.75 18.25 5.98 18.25 7.5V16.5C18.25 18.02 17.02 19.25 15.5 19.25ZM15.5 6.25C14.81 6.25 14.25 6.81 14.25 7.5V16.5C14.25 17.19 14.81 17.75 15.5 17.75C16.19 17.75 16.75 17.19 16.75 16.5V7.5C16.75 6.81 16.19 6.25 15.5 6.25Z'
                        fill='#9D9D9D'
                      />
                      <path
                        d='M8.5 19.25C6.98 19.25 5.75 18.02 5.75 16.5V13C5.75 11.48 6.98 10.25 8.5 10.25C10.02 10.25 11.25 11.48 11.25 13V16.5C11.25 18.02 10.02 19.25 8.5 19.25ZM8.5 11.75C7.81 11.75 7.25 12.31 7.25 13V16.5C7.25 17.19 7.81 17.75 8.5 17.75C9.19 17.75 9.75 17.19 9.75 16.5V13C9.75 12.31 9.19 11.75 8.5 11.75Z'
                        fill='#9D9D9D'
                      />
                    </svg>
                  </span>
                  Funds
                </Link>
              )}
            </Menu.Item>

            <Menu.Item
              key={'/developer-portal/settings/account'}
              className={`menu-item 
              ${
                currentPath === '/developer-portal/settings/account'
                  ? 'text-primary bg-BagYellow'
                  : ''
              } mt-12
            `}
              style={{
                backgroundColor:
                  currentPath === '/developer-portal/settings/account'
                    ? '#FBE9B2'
                    : '',
                borderRadius:
                  currentPath === '/developer-portal/settings/account'
                    ? '4px'
                    : '',
              }}
            >
              <Link
                className='menu-link-text'
                onClick={closeSidebar}
                to='/developer-portal/settings/account'
              >
                <span style={{ marginRight: '8px' }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <path
                      d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z'
                      fill='#9D9D9D'
                    />
                    <path
                      d='M15.5 19.25C13.98 19.25 12.75 18.02 12.75 16.5V7.5C12.75 5.98 13.98 4.75 15.5 4.75C17.02 4.75 18.25 5.98 18.25 7.5V16.5C18.25 18.02 17.02 19.25 15.5 19.25ZM15.5 6.25C14.81 6.25 14.25 6.81 14.25 7.5V16.5C14.25 17.19 14.81 17.75 15.5 17.75C16.19 17.75 16.75 17.19 16.75 16.5V7.5C16.75 6.81 16.19 6.25 15.5 6.25Z'
                      fill='#9D9D9D'
                    />
                    <path
                      d='M8.5 19.25C6.98 19.25 5.75 18.02 5.75 16.5V13C5.75 11.48 6.98 10.25 8.5 10.25C10.02 10.25 11.25 11.48 11.25 13V16.5C11.25 18.02 10.02 19.25 8.5 19.25ZM8.5 11.75C7.81 11.75 7.25 12.31 7.25 13V16.5C7.25 17.19 7.81 17.75 8.5 17.75C9.19 17.75 9.75 17.19 9.75 16.5V13C9.75 12.31 9.19 11.75 8.5 11.75Z'
                      fill='#9D9D9D'
                    />
                  </svg>{' '}
                </span>
                Profile Settings
              </Link>
            </Menu.Item>
          </Menu>
        )}
      </nav>
    </div>
  );
};

// v2/core/internal/onboardingProfile/get

export default AgentSideBar;
