import { message } from 'antd';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import API_SERVICE from '../../services/api/service';
import {
  ADD_QUESTIONAIRE,
  GET_QUESTIONAIRE,
  UPDATE_QUESTIONAIRE,
} from '../../services/react-query/constants';
import { CorporateData, UpdateCorporateData } from '../../utils/verify_types';

export const useAddQuestoonairre = () => {
  const addQuestionairre = useMutation({
    mutationFn: (data: CorporateData) => {
      return API_SERVICE._addQuestionairre(data);
    },
    mutationKey: [ADD_QUESTIONAIRE],
    onSuccess: (data: AxiosResponse) => {
      localStorage.removeItem('DocumentCentre');
      localStorage.removeItem('CorporateInformation');
      localStorage.removeItem('BusinessActivity');
      localStorage.removeItem('OwnershipStructure');
      localStorage.removeItem('CompanyDirector');
      localStorage.removeItem('CorporateGovernance');
      localStorage.removeItem('PolicyProcedure');
      localStorage.removeItem('RiskAssessment');
      localStorage.removeItem('KycCdd');
      localStorage.removeItem('TransactionMonitoring');
      localStorage.removeItem('SanctionScreening');
      localStorage.removeItem('RecordRetention');
      localStorage.removeItem('BusinessAudit');
      localStorage.removeItem('AmlTraining');
      localStorage.removeItem('FatcaCompliance');
      message.success(data?.data?.message);
    },
    onError: (error: AxiosError | any) => {
      const errorMessage = error?.response?.data?.errors;
      typeof errorMessage === 'string'
        ? message.error(error?.response?.data?.errors)
        : message.error(error?.response?.data?.errors[0]);
    },
  });
  return { addQuestionairre };
};

export const useUpdateQuestoonairre = () => {
  const updateQuestionairre = useMutation({
    mutationFn: (data: UpdateCorporateData) => {
      return API_SERVICE._updateQuestionairre(data);
    },
    mutationKey: [UPDATE_QUESTIONAIRE],
    onSuccess: (data: AxiosResponse) => {
      message.success(data?.data?.message);
    },
    onError: (error: AxiosError | any) => {
      console.log(error?.response, 'error');
      const errorMessage = error?.response?.data?.errors;
      typeof errorMessage === 'string'
        ? message.error(error?.response?.data?.errors)
        : message.error(error?.response?.data?.errors[0]);
    },
  });
  return { updateQuestionairre };
};

export const useGetAllQuestionairre = () => {
  const { data, isFetching, isLoading, refetch, isError } = useQuery({
    queryKey: [GET_QUESTIONAIRE],
    queryFn: () => API_SERVICE._getQuestionairre(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      console.log(error?.response?.data?.status_code, 'error');
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        message.error(errors[0]);
      } else if (typeof errors === 'string') {
        message.error(errors);
      }
    },
  });

  return {
    questionairre: data?.data?.data,
    isFetching,
    isLoading,
    refetch,
    isfailed: isError,
  };
};
