import React, { createContext, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
// import { useGetAllQuestionairre } from "../hooks/kyc/kyc.hook";
// import { useGetAllCountries } from "../hooks/utils/utils.hook";
// import { verifyObject } from "../utils/src/formatCuurency";
import { Country } from '../utils/types';
import {
  AmlTraining,
  BusinessActivity,
  BusinessAudit,
  CompanyDirector,
  CorporateGovernance,
  CorporateInformation,
  DocumentCentre,
  FatcaCompliance,
  KycCdd,
  OwnershipStructure,
  PolicyProcedure,
  RecordRetention,
  RiskAssessment,
  SanctionScreening,
  TransactionMonitoring,
} from '../utils/verify_types';
// import { useApi } from "./ApiContext";

interface KycState {
  isSubmitting: boolean;
  steps: number;
  CorporateInformation: CorporateInformation;
  BusinessActivity: BusinessActivity;
  OwnershipStructure: OwnershipStructure;
  CorporateGovernance: CorporateGovernance;
  PolicyProcedure: PolicyProcedure;
  RiskAssessment: RiskAssessment;
  KycCdd: KycCdd;
  TransactionMonitoring: TransactionMonitoring;
  SanctionScreening: SanctionScreening;
  RecordRetention: RecordRetention;
  BusinessAudit: BusinessAudit;
  AmlTraining: AmlTraining;
  FatcaCompliance: FatcaCompliance;
  DocumentCentre: DocumentCentre;
  CompanyDirector: CompanyDirector;
}

interface KycContextType {
  kycState: KycState;
  setCorporateInformation: (info: CorporateInformation) => void;
  setBusinessActivity: (activity: BusinessActivity) => void;
  setOwnershipStructure: (info: OwnershipStructure) => void;
  setCorporateGovernance: (info: CorporateGovernance) => void;
  setPolicyProcedure: (info: PolicyProcedure) => void;
  setRiskAssessment: (info: RiskAssessment) => void;
  setKycCdd: (info: KycCdd) => void;
  setTransactionMonitoring: (info: TransactionMonitoring) => void;
  setSanctionScreening: (info: SanctionScreening) => void;
  setRecordRetention: (info: RecordRetention) => void;
  setBusinessAudit: (info: BusinessAudit) => void;
  setAmlTraining: (info: AmlTraining) => void;
  setFatcaCompliance: (info: FatcaCompliance) => void;
  setDocumentCentre: (info: DocumentCentre) => void;
  setCompanyDirector: (info: CompanyDirector) => void;
  setKycState: React.Dispatch<React.SetStateAction<KycState>>;
  setCounter: (direction: 'increment' | 'decrement', path?: string) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setRefetch: () => void;
  COUNTRY_JSON: Country[];
}

const KycContext = createContext<KycContextType | undefined>(undefined);

export const useKycContext = () => {
  const context = useContext(KycContext);
  if (!context) {
    throw new Error('useKycContext must be used within a FormProvider');
  }
  return context;
};

export const KycProvider = ({ children }: { children: React.ReactNode }) => {
  // const { questionairre, refetch, isfailed } = useGetAllQuestionairre();
  // const apiKey = useApi();
  // const { countries, isError } = useGetAllCountries(apiKey);

  // const country_json: Country[] = countries?.data?.data;
  const COUNTRY_JSON: any[] = [];

  const [kycState, setKycState] = useState<KycState>({
    isSubmitting: false,
    steps: 1,
    BusinessActivity: {
      company_industry: '',
      company_industry_description: '',
      regulatory_institution_name: '',
      has_legal_regulatory_conviction: false,
      legal_regulatory_conviction_description: '',
    },
    CorporateInformation: {
      legal_name: '',
      registration_incorporation_number: '',
      tax_id: '',
      country_of_incorporation: {
        country_name: '',
        country_iso_2: '',
        country_iso_3: '',
      },
      legal_type: '',
      website: '',
      nature_of_business: '',
      date_of_incorporation: '',
      registered_address: '',
      principle_place_of_business: {
        country_name: '',
        country_iso_2: '',
        country_iso_3: '',
      },
      is_publicly_listed: false,
    },
    OwnershipStructure: {
      shareholder_name: '',
      holding_percentage: 0,
      identification_type: '',
      identification_number: '',
      is_pep: false,
    },
    CorporateGovernance: {
      exist_established_aml: false,
      compliance_officer_name: '',
      compliance_officer_email: '',
      compliance_officer_phone_number: '',
      exist_adequate_non_compliance_sanction: false,
      compliance_regulator_name: '',
    },
    PolicyProcedure: {
      exist_new_ml_risk_policy_procedure: false,
      policy_procedure_review_frequency: '',
      policy_procedure_reviewer: '',
      in_business_with_shell_companies: false,
      exist_policy_prohibit_business_with_shell_companies: false,
      exist_pep_policy: false,
    },
    RiskAssessment: {
      exist_ml_risk_classification: false,
      is_ml_risk_classification_used: false,
      exist_prohibit_ml_risk_customer: false,
      prohibit_ml_risk_customer_list: [],
      high_risk_business_locations: [],
      is_enhanced_due_diligence_pep_high_risk_customer: false,
      pep_approver: '',
      is_service_provided_selected_industry: false,
      selected_industry_risk_level: {},
    },
    KycCdd: {
      exist_approved_policy_procedure: false,
      onboarding_process_description: '',
      kyc_cdd_frequency: '',
      is_kyc_cdd_frequency_risk_dependent: false,
      additional_high_risk_kyc_cdd_requirement: '',
      approval_high_risk_kyc_cdd_requirement: '',
      exist_pep_screening: false,
      exist_anonymous_on_behalf_of_transaction: false,
      exist_corporate_ubo_policy_procedure: false,
      is_aml_partially_or_fully_outsourced: false,
      outsourced_aml_role_description: '',
    },
    TransactionMonitoring: {
      exist_automated_transaction_monitoring: false,
      transaction_monitoring_configuration_description: '',
      sar_ser_review_report_description: '',
      monthly_average_transaction_monitoring_alert: 0,
      is_aml_cft_employee_training_up_to_date: false,
      exist_employee_aml_cft_violation_sanction: false,
      exist_employee_aml_cft_whistleblower_protection: false,
    },
    SanctionScreening: {
      exist_sanction_screening: false,
      sanction_screening_list: [],
    },
    RecordRetention: {
      exist_record_retention_policy: false,
      record_retention_policy_description: '',
      record_retention_period_in_months: 0,
      record_type: '',
    },
    BusinessAudit: {
      exist_non_governmental_aml_ctf_audit: false,
      non_governmental_aml_ctf_audit_frequency: '',
      is_aml_ctf_audit_shared_with_company_directors: false,
      last_aml_ctf_audit_assessment_date: '',
      last_aml_ctf_audit_assessment_regulator: '',
      exist_last_aml_ctf_audit_assessment_material_finding: false,
    },
    AmlTraining: {
      is_new_existing_aml_changes_employee_communicated: false,
      exist_formal_aml_kyc_employee_training: false,
      formal_aml_kyc_employee_training_description: '',
      formal_aml_kyc_employee_trainer: '',
      aml_kyc_assessment_regulator: '',
      aml_kyc_assessment_frequency: '',
      is_formal_aml_kyc_training_for_all_employees: false,
      aml_kyc_training_employee_level: '',
      exist_current_knowledge_aml_kyc_policy_procedure: false,
      exist_aml_kyc_policy_procedure_awareness: false,
      exist_aml_breach_consequence_awareness: false,
      exist_aml_kyc_employee_training_monitoring: false,
      aml_kyc_employee_training_evidence_url: '',
    },
    FatcaCompliance: {
      is_fatca_compliant: false,
    },
    DocumentCentre: {
      company_registration_document_url: '',
      company_profile_url: '',
      company_aml_cft_policy_url: '',
      company_kyc_policy_url: '',
      company_data_protection_policy_url: '',
      company_operating_license_url: '',
      company_customer_dispute_resolution_policy_url: '',
      company_information_security_policy_url: '',
      company_record_retention_policy_url: '',
    },
    CompanyDirector: {
      name: '',
      position: '',
      identification_type: '',
      identification_number: '',
      is_pep: false,
      nationality: {},
    },
  });

  const setCorporateInformation = (info: CorporateInformation) => {
    setKycState((prevState) => ({
      ...prevState,
      CorporateInformation: info,
    }));
  };

  const setBusinessActivity = (activity: BusinessActivity) => {
    setKycState((prevState) => ({
      ...prevState,
      BusinessActivity: activity,
    }));
  };

  const setOwnershipStructure = (info: OwnershipStructure) => {
    setKycState((prevState) => ({
      ...prevState,
      OwnershipStructure: info,
    }));
  };

  const setCompanyDirector = (info: CompanyDirector) => {
    setKycState((prevState) => ({
      ...prevState,
      CompanyDirector: info,
    }));
  };

  const setCorporateGovernance = (info: CorporateGovernance) => {
    setKycState((prevState) => ({
      ...prevState,
      CorporateGovernance: info,
    }));
  };

  const setPolicyProcedure = (info: PolicyProcedure) => {
    setKycState((prevState) => ({
      ...prevState,
      PolicyProcedure: info,
    }));
  };

  const setRiskAssessment = (info: RiskAssessment) => {
    setKycState((prevState) => ({
      ...prevState,
      RiskAssessment: info,
    }));
  };

  const setKycCdd = (info: KycCdd) => {
    setKycState((prevState) => ({
      ...prevState,
      KycCdd: info,
    }));
  };

  const setTransactionMonitoring = (info: TransactionMonitoring) => {
    setKycState((prevState) => ({
      ...prevState,
      TransactionMonitoring: info,
    }));
  };

  const setSanctionScreening = (info: SanctionScreening) => {
    setKycState((prevState) => ({
      ...prevState,
      SanctionScreening: info,
    }));
  };

  const setRecordRetention = (info: RecordRetention) => {
    setKycState((prevState) => ({ ...prevState, RecordRetention: info }));
  };

  const setBusinessAudit = (info: BusinessAudit) => {
    setKycState((prevState) => ({
      ...prevState,
      BusinessAudit: info,
    }));
  };

  const setAmlTraining = (info: AmlTraining) => {
    setKycState((prevState) => ({
      ...prevState,
      AmlTraining: info,
    }));
  };

  const setFatcaCompliance = (info: FatcaCompliance) => {
    setKycState((prevState) => ({
      ...prevState,
      FatcaCompliance: info,
    }));
  };

  const setDocumentCentre = (info: DocumentCentre) => {
    setKycState((prevState) => ({
      ...prevState,
      DocumentCentre: info,
    }));
  };

  const setCounter = (direction: 'increment' | 'decrement', path?: string) => {
    setKycState((prevState) => ({
      ...prevState,
      steps:
        direction === 'increment'
          ? prevState.steps + 1
          : Math.max(prevState.steps - 1, 1),
    }));

    if (path) {
      return <Navigate to={path} state={kycState} />;
    }
  };

  const setIsSubmitting = (isSubmitting: boolean) => {
    setKycState((prevState) => ({
      ...prevState,
      isSubmitting,
    }));
  };

  const setRefetch = () => {};

  // const questionairreData: KycData = questionairre;

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.corporate_information) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       CorporateInformation: {
  //         legal_name: questionairreData?.corporate_information?.legal_name,
  //         registration_incorporation_number:
  //           questionairreData?.corporate_information
  //             ?.registration_incorporation_number,
  //         tax_id: questionairreData?.corporate_information?.tax_id,
  //         country_of_incorporation: {
  //           country_name:
  //             questionairreData?.corporate_information?.country_of_incorporation
  //               ?.country_name,
  //           country_iso_2:
  //             questionairreData?.corporate_information?.country_of_incorporation
  //               ?.country_iso_2,
  //           country_iso_3:
  //             questionairreData?.corporate_information?.country_of_incorporation
  //               ?.country_iso_3,
  //         },
  //         legal_type: questionairreData?.corporate_information?.legal_type,
  //         website: questionairreData?.corporate_information?.website,
  //         nature_of_business:
  //           questionairreData?.corporate_information?.nature_of_business,
  //         date_of_incorporation:
  //           questionairreData?.corporate_information?.date_of_incorporation !==
  //           ""
  //             ? moment(
  //                 questionairreData?.corporate_information
  //                   ?.date_of_incorporation,
  //                 "DD-MM-YYYY"
  //               )
  //             : "",
  //         registered_address:
  //           questionairreData?.corporate_information?.registered_address,
  //         principle_place_of_business: {
  //           country_name:
  //             questionairreData?.corporate_information
  //               ?.principle_place_of_business?.country_name,
  //           country_iso_2:
  //             questionairreData?.corporate_information
  //               ?.principle_place_of_business?.country_iso_2,
  //           country_iso_3:
  //             questionairreData?.corporate_information
  //               ?.principle_place_of_business?.country_iso_3,
  //         },
  //         is_publicly_listed:
  //           questionairreData?.corporate_information?.is_publicly_listed,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.business_activity) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       BusinessActivity: {
  //         company_industry:
  //           questionairreData?.business_activity?.company_industry,
  //         company_industry_description:
  //           questionairreData?.business_activity?.company_industry,
  //         regulatory_institution_name:
  //           questionairreData?.business_activity?.regulatory_institution_name,
  //         has_legal_regulatory_conviction:
  //           questionairreData?.business_activity
  //             ?.has_legal_regulatory_conviction,
  //         legal_regulatory_conviction_description:
  //           questionairreData?.business_activity
  //             ?.legal_regulatory_conviction_description,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.ownership_structure) === true &&
  //     verifyObject(questionairreData?.company_director) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       OwnershipStructure: {
  //         shareholder_name:
  //           questionairreData?.ownership_structure?.shareholder_name,
  //         holding_percentage:
  //           questionairreData?.ownership_structure?.holding_percentage,
  //         identification_type:
  //           questionairreData?.ownership_structure?.identification_type,
  //         identification_number:
  //           questionairreData?.ownership_structure?.identification_number,
  //         is_pep: questionairreData?.ownership_structure?.is_pep,
  //       },
  //       CompanyDirector: {
  //         name: questionairreData?.company_director?.name,
  //         position: questionairreData?.company_director?.position,
  //         identification_type:
  //           questionairreData?.company_director?.identification_type,
  //         identification_number:
  //           questionairreData?.company_director?.identification_number,
  //         is_pep: questionairreData?.company_director?.is_pep,
  //         nationality: {
  //           country_name:
  //             questionairreData?.company_director?.nationality?.country_name,
  //           country_iso_2:
  //             questionairreData?.company_director?.nationality?.country_iso_2,
  //           country_iso_3:
  //             questionairreData?.company_director?.nationality?.country_iso_3,
  //         },
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.corporate_governance) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       CorporateGovernance: {
  //         exist_established_aml:
  //           questionairreData?.corporate_governance?.exist_established_aml,
  //         compliance_officer_name:
  //           questionairreData?.corporate_governance?.compliance_officer_name,
  //         compliance_officer_email:
  //           questionairreData?.corporate_governance?.compliance_officer_email,
  //         compliance_officer_phone_number:
  //           questionairreData?.corporate_governance
  //             ?.compliance_officer_phone_number,
  //         exist_adequate_non_compliance_sanction:
  //           questionairreData?.corporate_governance
  //             ?.exist_adequate_non_compliance_sanction,
  //         compliance_regulator_name:
  //           questionairreData?.corporate_governance?.compliance_regulator_name,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.policy_procedure) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       PolicyProcedure: {
  //         exist_new_ml_risk_policy_procedure:
  //           questionairreData?.policy_procedure
  //             ?.exist_new_ml_risk_policy_procedure,
  //         policy_procedure_review_frequency:
  //           questionairreData?.policy_procedure
  //             ?.policy_procedure_review_frequency,
  //         policy_procedure_reviewer:
  //           questionairreData?.policy_procedure?.policy_procedure_reviewer,
  //         in_business_with_shell_companies:
  //           questionairreData?.policy_procedure
  //             ?.in_business_with_shell_companies,
  //         exist_policy_prohibit_business_with_shell_companies:
  //           questionairreData?.policy_procedure
  //             ?.exist_policy_prohibit_business_with_shell_companies,
  //         exist_pep_policy:
  //           questionairreData?.policy_procedure?.exist_pep_policy,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.risk_assessment) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       RiskAssessment: {
  //         exist_ml_risk_classification:
  //           questionairreData?.risk_assessment?.exist_ml_risk_classification,
  //         is_ml_risk_classification_used:
  //           questionairreData?.risk_assessment?.is_ml_risk_classification_used,
  //         exist_prohibit_ml_risk_customer:
  //           questionairreData?.risk_assessment?.exist_prohibit_ml_risk_customer,
  //         prohibit_ml_risk_customer_list:
  //           questionairreData?.risk_assessment?.prohibit_ml_risk_customer_list,
  //         high_risk_business_locations:
  //           questionairreData?.risk_assessment?.high_risk_business_locations,
  //         is_enhanced_due_diligence_pep_high_risk_customer:
  //           questionairreData?.risk_assessment
  //             ?.is_enhanced_due_diligence_pep_high_risk_customer,
  //         pep_approver: questionairreData?.risk_assessment?.pep_approver,
  //         is_service_provided_selected_industry:
  //           questionairreData?.risk_assessment
  //             ?.is_service_provided_selected_industry,
  //         selected_industry_risk_level: {},
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.kyc_cdd) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       KycCdd: {
  //         exist_approved_policy_procedure:
  //           questionairreData?.kyc_cdd?.exist_approved_policy_procedure,
  //         onboarding_process_description:
  //           questionairreData?.kyc_cdd?.onboarding_process_description,
  //         kyc_cdd_frequency: questionairreData?.kyc_cdd?.kyc_cdd_frequency,
  //         is_kyc_cdd_frequency_risk_dependent:
  //           questionairreData?.kyc_cdd?.is_kyc_cdd_frequency_risk_dependent,
  //         additional_high_risk_kyc_cdd_requirement:
  //           questionairreData?.kyc_cdd
  //             ?.additional_high_risk_kyc_cdd_requirement,
  //         approval_high_risk_kyc_cdd_requirement:
  //           questionairreData?.kyc_cdd?.approval_high_risk_kyc_cdd_requirement,
  //         exist_pep_screening: questionairreData?.kyc_cdd?.exist_pep_screening,
  //         exist_anonymous_on_behalf_of_transaction:
  //           questionairreData?.kyc_cdd
  //             ?.exist_anonymous_on_behalf_of_transaction,
  //         exist_corporate_ubo_policy_procedure:
  //           questionairreData?.kyc_cdd?.exist_corporate_ubo_policy_procedure,
  //         is_aml_partially_or_fully_outsourced:
  //           questionairreData?.kyc_cdd?.is_aml_partially_or_fully_outsourced,
  //         outsourced_aml_role_description:
  //           questionairreData?.kyc_cdd?.outsourced_aml_role_description,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.transaction_monitoring) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       TransactionMonitoring: {
  //         exist_automated_transaction_monitoring:
  //           questionairreData?.transaction_monitoring
  //             ?.exist_automated_transaction_monitoring,
  //         transaction_monitoring_configuration_description:
  //           questionairreData?.transaction_monitoring
  //             ?.transaction_monitoring_configuration_description,
  //         sar_ser_review_report_description:
  //           questionairreData?.transaction_monitoring
  //             ?.sar_ser_review_report_description,
  //         monthly_average_transaction_monitoring_alert:
  //           questionairreData?.transaction_monitoring
  //             ?.monthly_average_transaction_monitoring_alert,
  //         is_aml_cft_employee_training_up_to_date:
  //           questionairreData?.transaction_monitoring
  //             ?.is_aml_cft_employee_training_up_to_date,
  //         exist_employee_aml_cft_violation_sanction:
  //           questionairreData?.transaction_monitoring
  //             ?.exist_employee_aml_cft_violation_sanction,
  //         exist_employee_aml_cft_whistleblower_protection:
  //           questionairreData?.transaction_monitoring
  //             ?.exist_employee_aml_cft_whistleblower_protection,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.sanction_screening) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       SanctionScreening: {
  //         exist_sanction_screening:
  //           questionairreData?.sanction_screening?.exist_sanction_screening,
  //         sanction_screening_list:
  //           questionairreData?.sanction_screening?.exist_sanction_screening ===
  //           true
  //             ? questionairreData?.sanction_screening?.sanction_screening_list
  //             : [],
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.record_retention) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       RecordRetention: {
  //         exist_record_retention_policy:
  //           questionairreData?.record_retention?.exist_record_retention_policy,
  //         record_retention_policy_description:
  //           questionairreData?.record_retention
  //             ?.record_retention_policy_description,
  //         record_retention_period_in_months:
  //           questionairreData?.record_retention
  //             ?.record_retention_period_in_months,
  //         record_type: questionairreData?.record_retention?.record_type,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.business_audit) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       BusinessAudit: {
  //         exist_non_governmental_aml_ctf_audit:
  //           questionairreData?.business_audit
  //             ?.exist_non_governmental_aml_ctf_audit,
  //         non_governmental_aml_ctf_audit_frequency:
  //           questionairreData?.business_audit
  //             ?.non_governmental_aml_ctf_audit_frequency,
  //         is_aml_ctf_audit_shared_with_company_directors:
  //           questionairreData?.business_audit
  //             ?.is_aml_ctf_audit_shared_with_company_directors,
  //         last_aml_ctf_audit_assessment_date:
  //           questionairreData?.business_audit
  //             ?.last_aml_ctf_audit_assessment_date,
  //         last_aml_ctf_audit_assessment_regulator:
  //           questionairreData?.business_audit
  //             ?.last_aml_ctf_audit_assessment_regulator,
  //         exist_last_aml_ctf_audit_assessment_material_finding:
  //           questionairreData?.business_audit
  //             ?.exist_last_aml_ctf_audit_assessment_material_finding,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.aml_training) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       AmlTraining: {
  //         is_new_existing_aml_changes_employee_communicated:
  //           questionairreData?.aml_training
  //             ?.is_new_existing_aml_changes_employee_communicated,
  //         exist_formal_aml_kyc_employee_training:
  //           questionairreData?.aml_training
  //             ?.exist_formal_aml_kyc_employee_training,
  //         formal_aml_kyc_employee_training_description:
  //           questionairreData?.aml_training
  //             ?.formal_aml_kyc_employee_training_description,
  //         formal_aml_kyc_employee_trainer:
  //           questionairreData?.aml_training?.formal_aml_kyc_employee_trainer,
  //         aml_kyc_assessment_regulator:
  //           questionairreData?.aml_training?.aml_kyc_assessment_regulator,
  //         aml_kyc_assessment_frequency:
  //           questionairreData?.aml_training?.aml_kyc_assessment_frequency,
  //         is_formal_aml_kyc_training_for_all_employees:
  //           questionairreData?.aml_training
  //             ?.is_formal_aml_kyc_training_for_all_employees,
  //         aml_kyc_training_employee_level:
  //           questionairreData?.aml_training?.aml_kyc_training_employee_level,
  //         exist_current_knowledge_aml_kyc_policy_procedure:
  //           questionairreData?.aml_training
  //             ?.exist_current_knowledge_aml_kyc_policy_procedure,
  //         exist_aml_kyc_policy_procedure_awareness:
  //           questionairreData?.aml_training
  //             ?.exist_aml_breach_consequence_awareness,
  //         exist_aml_breach_consequence_awareness:
  //           questionairreData?.aml_training
  //             ?.exist_aml_breach_consequence_awareness,
  //         exist_aml_kyc_employee_training_monitoring:
  //           questionairreData?.aml_training
  //             ?.exist_aml_kyc_employee_training_monitoring,
  //         aml_kyc_employee_training_evidence_url:
  //           questionairreData?.aml_training
  //             ?.aml_kyc_employee_training_evidence_url,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.fatca_compliance) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       FatcaCompliance: {
  //         is_fatca_compliant:
  //           questionairreData?.fatca_compliance?.is_fatca_compliant,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  // useEffect(() => {
  //   if (
  //     questionairreData !== undefined &&
  //     verifyObject(questionairreData?.document_centre) === true
  //   ) {
  //     setKycState((prevState) => ({
  //       ...prevState,
  //       DocumentCentre: {
  //         company_registration_document_url:
  //           questionairreData?.document_centre
  //             ?.company_registration_document_url,
  //         company_profile_url:
  //           questionairreData?.document_centre?.company_profile_url,
  //         company_aml_cft_policy_url:
  //           questionairreData?.document_centre?.company_aml_cft_policy_url,
  //         company_kyc_policy_url:
  //           questionairreData?.document_centre?.company_kyc_policy_url,
  //         company_data_protection_policy_url:
  //           questionairreData?.document_centre
  //             ?.company_data_protection_policy_url,
  //         company_operating_license_url:
  //           questionairreData?.document_centre?.company_operating_license_url,
  //         company_customer_dispute_resolution_policy_url:
  //           questionairreData?.document_centre
  //             ?.company_customer_dispute_resolution_policy_url,
  //         company_information_security_policy_url:
  //           questionairreData?.document_centre
  //             ?.company_information_security_policy_url,
  //         company_record_retention_policy_url:
  //           questionairreData?.document_centre
  //             ?.company_record_retention_policy_url,
  //       },
  //     }));
  //   }
  // }, [questionairreData]);

  return (
    <KycContext.Provider
      value={{
        kycState,
        setCorporateInformation,
        setBusinessActivity,
        setKycState,
        setCounter,
        setIsSubmitting,
        setOwnershipStructure,
        setCorporateGovernance,
        setPolicyProcedure,
        setRiskAssessment,
        setKycCdd,
        setTransactionMonitoring,
        setSanctionScreening,
        setRecordRetention,
        setBusinessAudit,
        setAmlTraining,
        setFatcaCompliance,
        setDocumentCentre,
        COUNTRY_JSON,
        setCompanyDirector,
        setRefetch,
      }}
    >
      {children}
    </KycContext.Provider>
  );
};
