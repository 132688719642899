import { Button, Form, FormProps, Input, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKycContext } from '../../../../../context/KycContext';
import { Periods, YES_NO } from '../../../../../utils/data';
import { getLocalStorageData } from '../../../../../utils/src/formatCuurency';
import { StepProps } from '../../../../../utils/types';
import { PolicyProcedure } from '../../../../../utils/verify_types';

export const StepFive: React.FC<StepProps> = () => {
  const [form] = Form.useForm<PolicyProcedure>();
  const navigate = useNavigate();
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setPolicyProcedure,
    setRefetch,
  } = useKycContext();

  const isShellBusiness = Form.useWatch(
    'in_business_with_shell_companies',
    form
  );

  const onFinish: FormProps<PolicyProcedure>['onFinish'] = (values) => {
    if (values) {
      setPolicyProcedure(values);
      localStorage.setItem('PolicyProcedure', JSON.stringify(values));
      setRefetch();
      setIsSubmitting(false);
      setCounter('increment');
      navigate(`/developer-portal/kyc/risk_assessment`, {});
    }
  };

  const onFinishFailed: FormProps<PolicyProcedure>['onFinishFailed'] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const PolicyProcedure: any = getLocalStorageData('PolicyProcedure', {});
    if (PolicyProcedure) {
      form.setFieldsValue({
        exist_new_ml_risk_policy_procedure:
          PolicyProcedure?.exist_new_ml_risk_policy_procedure,
        policy_procedure_review_frequency:
          PolicyProcedure?.policy_procedure_review_frequency,
        policy_procedure_reviewer: PolicyProcedure?.policy_procedure_reviewer,
        in_business_with_shell_companies:
          PolicyProcedure?.in_business_with_shell_companies,
        exist_policy_prohibit_business_with_shell_companies:
          PolicyProcedure?.exist_policy_prohibit_business_with_shell_companies,
        exist_pep_policy: PolicyProcedure?.exist_pep_policy,
      });
    }
  }, []);

  return (
    <>
      <Form
        form={form}
        name='signInForm'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
      >
        <div className='grid grid-cols-1 gap-x-8 gap-y-0 pb-2 w-full'>
          <Form.Item<PolicyProcedure>
            name='exist_new_ml_risk_policy_procedure'
            label='Are there policies and procedures for assessing ML/FT risks in the development of new products?'
            className='w-2/3'
            rules={[
              {
                required: true,
                message: 'Please select an option',
              },
            ]}
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item className='w-full '>
            <div className='grid grid-cols-2 gap-x-5 pt-3'>
              <Form.Item<PolicyProcedure>
                name={'policy_procedure_review_frequency'}
                label='How often are the policies and 
procedures reviewed/ updated?'
                rules={[
                  {
                    required: true,
                    message: 'Please select policies review frequency',
                  },
                ]}
              >
                <Select
                  placeholder=''
                  options={[...Periods]}
                  size='large'
                  className='text-left'
                />
              </Form.Item>
              <Form.Item<PolicyProcedure>
                name={'policy_procedure_reviewer'}
                label='Who approves this review/update?'
                rules={[
                  {
                    required: true,
                    pattern: /^[a-zA-Z\s-]*$/,
                    message:
                      'Policy reviewer must not contain numbers and special characters',
                  },
                ]}
              >
                <Input size='large' placeholder='' />
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item className={'w-full py-3'}>
            <div className='grid grid-cols-2 gap-x-5'>
              <Form.Item<PolicyProcedure>
                name='in_business_with_shell_companies'
                label='Does the institution conduct business 
with shell banks/companies?'
                rules={[
                  {
                    required: true,
                    message: 'Please select an option',
                  },
                ]}
              >
                <Select
                  placeholder=''
                  options={[...YES_NO]}
                  size='large'
                  className='text-left'
                />
              </Form.Item>

              <Form.Item<PolicyProcedure>
                name='exist_policy_prohibit_business_with_shell_companies'
                label='If the answer is No, does the Institution have policies prohibiting accounts/relationships with shell banks?'
                rules={[
                  {
                    required: true,
                    message: 'Please select an option',
                  },
                ]}
              >
                <Select
                  placeholder=''
                  options={[...YES_NO]}
                  size='large'
                  className='text-left'
                />
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item<PolicyProcedure>
            name='exist_pep_policy'
            label='Does the Institution have policies covering relationships with Politically Exposed Persons (PEP’s), their family and close associates?'
            className='w-2/3'
            rules={[
              {
                required: true,
                message: 'Please select an option',
              },
            ]}
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>
        </div>

        <Space
          size={'middle'}
          align='baseline'
          style={{ float: 'left' }}
          className='mt-10'
        >
          <Form.Item>
            <Button
              type='default'
              htmlType='button'
              className='bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white'
              style={{ height: '56px', fontWeight: 'bold' }}
              onClick={() =>
                navigate(`/developer-portal/kyc/corporate_governance`, {})
              }
            >
              Back
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='small'
              className='bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation'
              style={{
                height: '56px',
                fontWeight: 'bold',
              }}
              loading={kycState.isSubmitting}
            >
              Save and Continue
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </>
  );
};
