import { Button, Form, FormProps, Input, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useKycContext } from '../../../../../context/KycContext';
import { useApiKey } from '../../../../../hooks/auth/auth.hooks';
import {
  useGetAllCountries,
  useGetAllCountriesMutation,
} from '../../../../../hooks/utils/utils.hook';
import { IDENTITYONE, YES_NO } from '../../../../../utils/data';
import {
  filterCountriesByISO3,
  getLocalStorageData,
  toTwoDecimalPlaces,
} from '../../../../../utils/src/formatCuurency';
import { Country, StepProps } from '../../../../../utils/types';
import {
  CompanyDirector,
  OwnershipStructure,
} from '../../../../../utils/verify_types';

export const StepThree: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setOwnershipStructure,
    setCompanyDirector,
    setRefetch,
  } = useKycContext();
  const navigate = useNavigate();
  const [nations, setNations] = useState<any>([]);

  const IDENTITY = IDENTITYONE;

  const { COUNTRY_JSON } = useGetAllCountries();
  const { getCountries } = useGetAllCountriesMutation();
  const { getApiKey } = useApiKey();

  const fetchCountries = async (): Promise<any> => {
    try {
      const response = await getApiKey.mutateAsync();
      if (response.status === 200) {
        const apiKey = response?.data?.data;
        const country = await getCountries.mutateAsync(apiKey);
        if (country) {
          const country_json: Country[] = country?.data?.data;
          const COUNTRY_JSON: any[] = country_json
            ? country_json?.map((i: Country) => {
                return {
                  label: i.country_name,
                  value: i.country_iso_3,
                  stateCode: i.country_iso_2,
                };
              })
            : [];
          setNations(COUNTRY_JSON);
          return COUNTRY_JSON;
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const onFinish: FormProps<any>['onFinish'] = (values) => {
    const country_json: any[] = nations || COUNTRY_JSON;
    if (values) {
      const country_of_incorporation = filterCountriesByISO3(
        country_json,
        values.nationality
      );

      const OwnershipStructureInfo: OwnershipStructure = {
        shareholder_name: values?.shareholder_name,
        holding_percentage: toTwoDecimalPlaces(values.holding_percentage),
        identification_type: values?.identification_type_one,
        identification_number: values?.identification_number_one,
        is_pep: values?.is_pep_one,
      };
      const CompanyDirectorInfo: CompanyDirector = {
        name: values?.name,
        position: values?.position,
        identification_type: values.identification_type_two,
        identification_number: values.identification_number_two,
        is_pep: values.is_pep_two,
        nationality: {
          country_name: country_of_incorporation[0]?.label,
          country_iso_2: country_of_incorporation[0]?.stateCode,
          country_iso_3: country_of_incorporation[0]?.value,
        },
      };
      localStorage.setItem(
        'OwnershipStructureInfo',
        JSON.stringify(OwnershipStructureInfo)
      );
      localStorage.setItem(
        'CompanyDirectorInfo',
        JSON.stringify(CompanyDirectorInfo)
      );
      setOwnershipStructure(OwnershipStructureInfo);
      setCompanyDirector(CompanyDirectorInfo);
      setRefetch();
      setIsSubmitting(false);
      setCounter('increment');
      navigate('/developer-portal/kyc/corporate_governance');
    }
  };

  const onFinishFailed: FormProps<any>['onFinishFailed'] = (errorInfo) => {
    return errorInfo;
  };

  useEffect(() => {
    const OwnershipStructure: any = getLocalStorageData(
      'OwnershipStructureInfo',
      {}
    );

    const CompanyDirector: any = getLocalStorageData('CompanyDirectorInfo', {});
    if (CompanyDirector && OwnershipStructure) {
      form.setFieldsValue({
        shareholder_name: OwnershipStructure?.shareholder_name,
        holding_percentage: OwnershipStructure?.holding_percentage,
        identification_type_one: OwnershipStructure?.identification_type,
        identification_number_one: OwnershipStructure?.identification_number,
        is_pep_one: OwnershipStructure?.is_pep,

        name: CompanyDirector?.name,
        position: CompanyDirector?.position,
        identification_type_two: CompanyDirector?.identification_type,
        identification_number_two: CompanyDirector?.identification_number,
        is_pep_two: CompanyDirector?.is_pep,
        nationality: CompanyDirector?.nationality?.country_iso_3,
      });
    }
  }, []);

  return (
    <Form
      form={form}
      name='signInForm'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      layout='vertical'
      initialValues={{ directors: [{}], owners: [{}] }}
      className='flex flex-col space-y-4'
    >
      {/* OWNERSHIP STRUCTURE */}
      <div className='flex flex-col space-y-3'>
        {/* <h5 className="text-start font-Poppins text-lg font-semibold text-BagFoundation">
          Ownership Structure
        </h5> */}
        <p className='text-start text-BagNeutralDarkFoundation font-Poppins font-normal text-base'>
          please provide full details of any natural or legal persons,
          partnership or trust holding 5% or more of the voting rights:
        </p>

        <div className='grid grid-cols-2 gap-x-8'>
          <Form.Item
            label='Name of Shareholder'
            name='shareholder_name'
            rules={[
              {
                required: true,
                message: 'Please input Name of Shareholder!',
              },
              {
                type: 'string',
                pattern: /^[a-zA-Z0-9\s-]*$/,
                message:
                  'Name of Shareholder must not contain special characters',
              },
            ]}
            className='w-full p-1'
          >
            <Input placeholder={'Enter Directors Name'} size='large' />
          </Form.Item>

          <Form.Item
            label='Percentage Holding (%)'
            name='holding_percentage'
            rules={[
              {
                required: true,
                message: 'Please input Percentage Holding!',
              },
              {
                pattern: /^[0-9,]+$/,
                message:
                  'Please enter only numbers, alphabets and special characters are not allowed',
              },
            ]}
            className='w-full p-1'
          >
            <Input placeholder={'Enter Directors Position'} size='large' />
          </Form.Item>

          <Form.Item
            name='identification_type_one'
            label='Means of Identification'
            rules={[
              {
                required: true,
                message:
                  "Please input your shareholder's means Of identification!",
              },
            ]}
            className='w-full p-1'
          >
            <Select
              placeholder='Select'
              showSearch={false}
              options={[...IDENTITY]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            label='ID Number'
            name='identification_number_one'
            rules={[
              {
                required: true,
                message: 'Please input your Directors ID Numberon!',
              },
            ]}
            className='w-full p-1'
          >
            <Input placeholder={'Enter Directors ID Number'} size='large' />
          </Form.Item>

          <Form.Item
            name='is_pep_one'
            label='PEP (Yes/No)'
            rules={[
              {
                required: true,
                message: "Please select shareholder's PEP (Yes or NO)!",
              },
            ]}
            className='w-full p-1'
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>
        </div>
      </div>

      {/* DIRECTORS */}
      <div className='flex flex-col space-y-3 border-t'>
        <h5 className='text-start font-Poppins text-lg font-semibold text-BagFoundation pt-5'>
          Directors and Management Staff
        </h5>
        {/* <p className="text-start text-BagNeutralDarkFoundation font-Poppins font-normal text-base">
          Please Provide Full Details Of Any Natural Or Legal Persons,
          Partnership Or Trust Holding 5% Or More Of The Voting Rights:
        </p> */}
        <div className='grid grid-cols-2 gap-x-8'>
          <Form.Item
            name='name'
            label="Director's Name"
            rules={[
              {
                required: true,
                message: 'Please input your Directors Name!',
              },
            ]}
            className='w-full p-1'
          >
            <Input placeholder={'Enter Directors Name'} size='large' />
          </Form.Item>

          <Form.Item
            name='position'
            label='Position'
            rules={[
              {
                required: true,
                message: 'Please input your Directors Position!',
              },
            ]}
            className='w-full p-1'
          >
            <Input placeholder={'Enter Directors Position'} size='large' />
          </Form.Item>

          <Form.Item
            name='identification_type_two'
            label='Means of Identification'
            rules={[
              {
                required: true,
                message: 'Please input your Directors Means Of Identification!',
              },
            ]}
            className='w-full p-1'
          >
            <Select
              placeholder='Select'
              showSearch={false}
              options={[...IDENTITY]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='identification_number_two'
            label='ID Number'
            rules={[
              {
                required: true,
                message: 'Please input your Directors ID Number!',
              },
            ]}
            className='w-full p-1'
          >
            <Input placeholder={'Enter Directors ID Number'} size='large' />
          </Form.Item>

          <Form.Item
            name='nationality'
            label='Nationality'
            className='w-full p-1'
            rules={[
              {
                required: true,
                message: 'Please input your Directors Nationality!',
              },
            ]}
          >
            <Select
              showSearch
              options={nations}
              size='large'
              className='text-left'
              filterOption={(input, option: any) =>
                option?.label?.toLowerCase().includes(input?.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            name='is_pep_two'
            label='PEP (Yes/No)'
            rules={[
              {
                required: true,
                message: 'Please select Directors PEP (Yes or NO)!',
              },
            ]}
            className='w-full p-1'
          >
            <Select
              showSearch
              options={YES_NO}
              size='large'
              className='text-left'
            />
          </Form.Item>
        </div>
      </div>

      <Space
        size={'middle'}
        align='baseline'
        style={{ float: 'left' }}
        className='mt-10'
      >
        <Form.Item>
          <Button
            type='default'
            htmlType='button'
            className='bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white'
            style={{ height: '56px', fontWeight: 'bold' }}
            onClick={() =>
              navigate('/developer-portal/kyc/business_activity', {})
            }
          >
            Back
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            size='small'
            className='bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation'
            style={{
              height: '56px',
              fontWeight: 'bold',
            }}
            loading={kycState.isSubmitting}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
