import { Button, Form, FormProps, Select, Space } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKycContext } from '../../../../../context/KycContext';
import { YES_NO } from '../../../../../utils/data';
import { getLocalStorageData } from '../../../../../utils/src/formatCuurency';
import { StepProps } from '../../../../../utils/types';
import { FatcaCompliance } from '../../../../../utils/verify_types';

export const StepThirteen: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setFatcaCompliance,
    setRefetch,
  } = useKycContext();

  const onFinish: FormProps<FatcaCompliance>['onFinish'] = (values) => {
    if (values) {
      setFatcaCompliance(values);
      localStorage.setItem('FatcaCompliance', JSON.stringify(values));
      setRefetch();
      setCounter('increment');
      navigate(`/developer-portal/kyc/document_center`, {});
      setIsSubmitting(false);
    }
  };

  const onFinishFailed: FormProps<FatcaCompliance>['onFinishFailed'] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const FatcaCompliance: any = getLocalStorageData('FatcaCompliance', {});
    if (FatcaCompliance) {
      form.setFieldsValue({
        is_fatca_compliant: FatcaCompliance?.is_fatca_compliant,
      });
    }
  }, []);

  return (
    <Form
      name='signInForm'
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      layout='vertical'
    >
      <div className='grid grid-cols-2 gap-x-8 gap-y-7 pb-4 w-full'>
        <Form.Item<FatcaCompliance>
          name='is_fatca_compliant'
          label='Has your institution-initiated measures/developments to be in compliance with Foreign Account Tax Compliance Act (FATCA)?'
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...YES_NO]}
            size='large'
            className='text-left'
          />
        </Form.Item>
      </div>

      <Space
        size={'middle'}
        align='baseline'
        style={{ float: 'left' }}
        className='mt-10'
      >
        <Form.Item>
          <Button
            type='default'
            htmlType='button'
            className='bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white'
            style={{ height: '56px', fontWeight: 'bold' }}
            onClick={() => navigate(`/developer-portal/kyc/aml_training`, {})}
          >
            Back
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            size='small'
            className='bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation'
            style={{
              height: '56px',
              fontWeight: 'bold',
            }}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
