import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export const host = window.location.host;

export const instance = axios.create({
  baseURL:
    host === 'monibag.com' || host === 'api.monibag.com'
      ? `https://apigateway.monibag.com/v2`
      : `https://uat-apigateway.monibag.com/v2`,
});

export const X_API_KEY: any | string = localStorage.getItem('apiKey');

instance.interceptors.request.use(
  function (config: any): any {
    const token: string | any = sessionStorage.getItem('adminToken');
    const devToken = sessionStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['x-api-key'] = X_API_KEY;
    }

    if (devToken) {
      config.headers['Authorization'] = `Bearer ${devToken}`;
      config.headers['x-api-key'] = X_API_KEY;
      // config.headers["Require-Rate-Confirmation"] = false;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response: AxiosResponse) {
    return response;
  },
  function (error: any) {
    if (
      error?.status === 401 ||
      error?.response?.status === 401 ||
      error?.response?.data?.status_code === 401
    ) {
      toast.info('Token has expired');
      if (window.location.href.includes('developer-portal')) {
        window?.sessionStorage.removeItem('token');
        window.location.replace(`/developer-portal-login`);
        localStorage.clear();
      }
      if (window.location.href.includes('admin-portal')) {
        window?.sessionStorage?.removeItem('adminToken');
        window.location.replace(`/admin-portal/login`);
        localStorage.clear();
      }
    }
    return Promise.reject(error);
  }
);
