import React, { useEffect, useState } from 'react';

interface Props {
  resend?: any;
}

const TimerComponent: React.FC<Props> = ({ resend }) => {
  const [time, setTime] = useState<number>(300); // 2 minutes = 120 seconds
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (time > 0) {
      timer = setTimeout(() => setTime(time - 1), 1000);
    } else {
      setIsButtonVisible(true);
    }
    return () => clearTimeout(timer);
  }, [time]);

  const handleClick = () => {
    setIsButtonVisible(false);
    setTime(300); // Reset timer to 2 minutes
    resend();
  };

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <div>
      {isButtonVisible ? (
        <button
          style={{ border: 'none', backgroundColor: 'transparent' }}
          className='resend-text'
          onClick={handleClick}
        >
          Resend OTP
        </button>
      ) : (
        <h1 className='text-sm font-Poppins font-semibold'>
          {formatTime(time)}
        </h1>
      )}
    </div>
  );
};

export default TimerComponent;
