import { PaperClipOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  FormProps,
  Input,
  Select,
  Space,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKycContext } from '../../../../../context/KycContext';
import { host } from '../../../../../services/constants/instance';
import {
  Periods,
  Trainers,
  TrainingModes,
  YES_NO,
} from '../../../../../utils/data';
import { getLocalStorageData } from '../../../../../utils/src/formatCuurency';
import { StepProps } from '../../../../../utils/types';
import { AmlTraining } from '../../../../../utils/verify_types';

const url =
  host === 'monibag.com'
    ? `https://apigateway.monibag.com/v2`
    : process.env.REACT_APP_GATEWAY_BASE_URL;
const token = sessionStorage.getItem('token');

export const StepTwelve: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { kycState, setIsSubmitting, setCounter, setAmlTraining, setRefetch } =
    useKycContext();
  const [fileUrl, setFileUrl] = useState('');

  const exist_aml_kyc_employee_training_monitoring = Form.useWatch(
    'exist_aml_kyc_employee_training_monitoring',
    form
  );

  // const aml_kyc_employee_training_evidence_url = Form.useWatch(
  //   "aml_kyc_employee_training_evidence_url",
  //   form
  // );

  const onFinish: FormProps<AmlTraining>['onFinish'] = (values: any) => {
    if (values) {
      setAmlTraining(values);
      localStorage.setItem('AmlTraining', JSON.stringify(values));
      setRefetch();
      setCounter('increment');
      navigate(`/developer-portal/kyc/facta_compliance`, {});
      setIsSubmitting(false);
    }
  };

  const onFinishFailed: FormProps<AmlTraining>['onFinishFailed'] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const AmlTraining: any = getLocalStorageData('AmlTraining', {});
    if (AmlTraining) {
      form.setFieldsValue({
        is_new_existing_aml_changes_employee_communicated:
          AmlTraining?.is_new_existing_aml_changes_employee_communicated,
        exist_formal_aml_kyc_employee_training:
          AmlTraining?.exist_formal_aml_kyc_employee_training,
        formal_aml_kyc_employee_training_description:
          AmlTraining?.formal_aml_kyc_employee_training_description,
        formal_aml_kyc_employee_trainer:
          AmlTraining?.formal_aml_kyc_employee_trainer,
        aml_kyc_assessment_regulator: AmlTraining?.aml_kyc_assessment_regulator,
        aml_kyc_assessment_frequency: AmlTraining?.aml_kyc_assessment_frequency,
        is_formal_aml_kyc_training_for_all_employees:
          AmlTraining?.is_formal_aml_kyc_training_for_all_employees,
        aml_kyc_training_employee_level:
          AmlTraining?.aml_kyc_training_employee_level,
        exist_current_knowledge_aml_kyc_policy_procedure:
          AmlTraining?.exist_current_knowledge_aml_kyc_policy_procedure,
        exist_aml_kyc_policy_procedure_awareness:
          AmlTraining?.exist_aml_breach_consequence_awareness,
        exist_aml_breach_consequence_awareness:
          AmlTraining?.exist_aml_breach_consequence_awareness,
        exist_aml_kyc_employee_training_monitoring:
          AmlTraining?.exist_aml_kyc_employee_training_monitoring,
        aml_kyc_employee_training_evidence_url:
          AmlTraining?.aml_kyc_employee_training_evidence_url,
      });
    }
  }, []);

  const props: UploadProps = {
    name: 'document',
    maxCount: 1,
    action: `${url}/core/document/upload/save`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    async onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setFileUrl(info.file.name);
        const response = info?.file?.response?.data;
        if (response) {
          form.setFieldValue(
            'aml_kyc_employee_training_evidence_url',
            response
          );
        }
      } else if (info.file.status === 'error') {
        const errors = info.file?.response?.errors;
        if (Array.isArray(errors)) {
          const notifyErrors = () => {
            errors.forEach((error) => {
              message.error(error);
            });
          };
          notifyErrors();
        } else {
          message.error(errors);
        }
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: true,
  };

  return (
    <Form
      name='AmlTraining'
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      layout='vertical'
    >
      <div className='grid grid-cols-2 gap-x-8 gap-y-7 pb-4 w-full'>
        <Form.Item<AmlTraining>
          name='is_new_existing_aml_changes_employee_communicated'
          label='Does the institution communicate new AML‐related laws or changes to existing AML policies and practices to relevant employees?'
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...YES_NO]}
            size='large'
            className='text-left'
          />
        </Form.Item>
      </div>

      <Form.Item<AmlTraining>
        name='exist_formal_aml_kyc_employee_training'
        label='Does the institution have a formal training program in place to address anti-money laundering and KYC responsibilities?'
        className='w-1/2 mb-3'
        rules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
      >
        <Select
          placeholder=''
          options={[...YES_NO]}
          size='large'
          className='text-left'
        />
      </Form.Item>

      <div className='grid grid-cols-2 gap-x-8 gap-y-4 pb-2 w-full'>
        <Form.Item<AmlTraining>
          name='formal_aml_kyc_employee_training_description'
          label='If yes, please describe the mode of training'
          rules={[
            {
              required: exist_aml_kyc_employee_training_monitoring === true,
              message: 'Please describe the mode of training',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...TrainingModes]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <Form.Item<AmlTraining>
          name='formal_aml_kyc_employee_trainer'
          label='Who conducts the training'
          rules={[
            {
              required: true,
              message: 'Please select the trainer',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...Trainers]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <Form.Item<AmlTraining>
          name='aml_kyc_assessment_regulator'
          label='Which regulatory body conducted the exam?'
          rules={[
            {
              required: true,
              message: 'Please input the regulatory body',
            },
          ]}
        >
          <Input placeholder='' size='large' />
        </Form.Item>

        <Form.Item<AmlTraining>
          name='aml_kyc_assessment_frequency'
          label='What is the frequency'
          rules={[
            {
              required: true,
              message: 'Please select the frequency',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...Periods]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <Form.Item<AmlTraining>
          name='is_formal_aml_kyc_training_for_all_employees'
          label='Is the training for all staff'
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...YES_NO]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <Form.Item<AmlTraining>
          name='aml_kyc_training_employee_level'
          label='Levels of employees trained?'
          rules={[
            {
              required: true,
              message: 'Please input training levels',
            },
          ]}
        >
          <Input size='large' />
        </Form.Item>
      </div>

      <Form.Item<AmlTraining>
        name='exist_aml_kyc_policy_procedure_awareness'
        label='In your opinion, would you say that you possess good knowledge of and regularly updated on domestic and transnational money laundering schemes and typologies, including those involving the misuse of the institution’s products and services?'
        className='w-1/2 mb-3'
        rules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
      >
        <Select
          placeholder=''
          options={[...YES_NO]}
          size='large'
          className='text-left'
        />
      </Form.Item>

      <div className='grid grid-cols-2 gap-x-8 gap-y-4 pb-2 w-full'>
        <Form.Item<AmlTraining>
          name='exist_current_knowledge_aml_kyc_policy_procedure'
          label='Are you aware of AML compliance, reporting procedures, and obligations?'
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...YES_NO]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <Form.Item<AmlTraining>
          name='exist_aml_breach_consequence_awareness'
          label='Do you understand the legal consequences of AML compliance breaches?'
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...YES_NO]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <Form.Item<AmlTraining>
          name='exist_aml_kyc_employee_training_monitoring'
          label='Is there a mechanism in place to ensure that every employee requested to attend training actually attends?'
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...YES_NO]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <Form.Item<AmlTraining>
          name='aml_kyc_employee_training_evidence_url'
          label='Please provide evidence to show staff attendance to trainings and evidence of trainings.'
          rules={[
            {
              required: true,
              message: 'Please upload evidence',
            },
            { type: 'url', message: '' },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            listType='text'
            className='w-full flex flex-col space-y-1'
            maxCount={1}
            multiple={false}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[550px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'></span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            <small className='text-left font-Poppins'>
              supported files include: jpg, jpeg, png, pdf, and docx
            </small>
            {fileUrl !== '' && (
              <a
                className='cursor-pointer'
                href={fileUrl}
                target='_blank'
                rel='noreferrer'
              >
                <PaperClipOutlined />
                &nbsp;&nbsp;
                {fileUrl}
              </a>
            )}
          </Upload>
        </Form.Item>
      </div>

      <Space
        size={'middle'}
        align='baseline'
        style={{ float: 'left' }}
        className='mt-10'
      >
        <Form.Item>
          <Button
            type='default'
            htmlType='button'
            className='bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white'
            style={{ height: '56px', fontWeight: 'bold' }}
            onClick={() =>
              navigate(`/developer-portal/kyc/internal_and_external_audit`, {})
            }
          >
            Back
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            size='small'
            className='bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation'
            style={{
              height: '56px',
              fontWeight: 'bold',
            }}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
