import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

// import type { User } from '../../../../../shared/types';
import { message } from 'antd';
import { ErrorHandler } from '../../../services/ErrorHandler';
import { axiosInstance } from '../../../services/constants/AxiosInstance';
import { SuccessToast } from '../../../services/toast';

var token = sessionStorage.getItem('token');

const X_API_KEY = sessionStorage.getItem('apiKey');

async function requestSignin(values: any): Promise<any | null> {
  const { API_KEY, ...rest } = values;
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: '/account/signIn',
    method: 'POST',
    data: { ...rest },
    headers: { 'x-api-key': API_KEY ? API_KEY : `${X_API_KEY}` },
  });

  return { data, status };
}

async function requestDocumentUpload(values: any): Promise<any | null> {
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: '/core/document/upload/save',
    method: 'POST',
    data: values,
    headers: {
      'x-api-key': `${X_API_KEY}`,
      'X-Forwarded-Authorization': token,
    },
  });

  return { data, status };
}

async function requestSignup(values: any): Promise<any | null> {
  const { apiKey, ...rest } = values;
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: '/account/signUp',
    method: 'POST',
    data: { ...rest },
    headers: { 'x-api-key': apiKey ? apiKey : `${X_API_KEY}` },
  });

  return { data, status };
}

async function requestResetPassword(values: any): Promise<any | null> {
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: '/password/reset',
    method: 'POST',
    data: values,
    headers: {
      'Access-Control-Allow-Origin':
        'https://dev-apigateway.monibag.com, https://dev.monibag.com, http://localhost:3000',
      'Access-Control-Allow-Methods': 'GET, HEAD, OPTIONS, POST, PUT, PATCH',
      'Access-Control-Allow-Headers':
        ' Authorization, Auth-Token, Refresh-Token, Next-Page-Token, Pin, Content-Type, Accept, Cache-Control, X-Requested-With, Origin, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Request-Headers, Access-Control-Request-Method, Email, OTP, x-api-key, Role, Order-ID, Agent-Order-ID, Secret-ID, Secret, Secret-IDs, Bearer, Order-IDs, X-Forwarded-Authorization, Basic, Access-Control-Allow-Credentials',
      'X-Forwarded-Authorization': token,
      Authorization: `Bearer ${token}`,
    },
  });

  //   "http://dev-apigateway.monibag.com, https://dev.monibag.com"
  // static const String accessControlOrigin = 'https://dev-0.monibag.com, https://dev.monibag.com, https://uat.monibag.com, https://monibag.com';
  // static const String accessControlAllowHeaders = 'Authorization, Content-Type, Accept, X-Requested-With, Origin';
  // static const String accessControlAllowMethods = 'GET, HEAD, OPTIONS, POST, PUT, PATCH';
  // static const String cacheControl = 'no-cache, no-transform';
  // static final String xApiKey = EnvHelper.xApiKey();

  // options.headers.putIfAbsent('Access-Control-Allow-Origin', () => NetworkConstant.accessControlOrigin);
  // options.headers.putIfAbsent('Access-Control-Allow-Headers', () => NetworkConstant.accessControlAllowHeaders);
  // options.headers.putIfAbsent('Access-Control-Allow-Methods', () => NetworkConstant.accessControlAllowMethods);
  // options.headers.putIfAbsent('Cache-Control', () => NetworkConstant.cacheControl);
  // options.headers.putIfAbsent('x-api-key', () => NetworkConstant.xApiKey);
  // super.onRequest(options, handler);

  return { data, status };
}

async function requestValidateOTP(values: any): Promise<any | null> {
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: `/account/signUp/OTP`,
    method: 'POST',
    headers: {
      'x-api-key': `${X_API_KEY}`,
      Email: values.email,
      OTP: values.otp,
    },
  });

  return { data, status };
}

async function requestValidateOTPLogin(values: any): Promise<any | null> {
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: `/pin/signIn/OTP`,
    method: 'POST',
    headers: {
      'x-api-key': `${X_API_KEY}`,
      Email: values.email,
      OTP: values.otp,
    },
  });

  return { data, status };
}

async function requestAPIKey(): Promise<any | null> {
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: `/api-auth/get/gateway/key`,
    method: 'GET',
    headers: {
      'x-api-key': `${X_API_KEY}`,
      'X-Forwarded-Authorization': token,
    },
  });
  return { data, status };
}

async function requestGetAllPreFund(): Promise<any | null> {
  const { data, status }: AxiosResponse<any> = await axiosInstance({
    url: `/core/agentPreFund/getAll/byAgent?page=1&size=10`,
    method: 'GET',
    headers: {
      'x-api-key': `${X_API_KEY}`,
      'X-Forwarded-Authorization': token,
    },
  });
  return { data, status };
}

export function useRequestSignin(): any {
  return useMutation((value) => requestSignin(value), {
    onSuccess: (res) => {
      if (res?.data?.status_code === 200) {
        message.success('login successful');
        // window.location.href = "/developer-portal/home";
      } else {
        message.error(res?.data?.message);
      }
    },
    onError: (err: any) => {
      return err;
    },
  });
}

export function useRequestSignup(): any {
  return useMutation((value) => requestSignup(value), {
    onSuccess: (res) => {
      if (res?.data?.status_code === 200) {
        message.success(res?.data?.message);
      } else {
        console.log(res?.data?.message);

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      message.error(err?.response?.data?.errors['0']);
    },
  });
}

export function useRequestValidateOtp(): any {
  return useMutation((value) => requestValidateOTP(value), {
    onSuccess: (res) => {
      if (res?.data?.status_code === 200) {
        // message.success(res?.data?.message);
      } else {
        // ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      message.error(err?.response?.data?.errors[0]);
    },
  });
}

export function useRequestValidateOtpLogin(): any {
  return useMutation((value) => requestValidateOTPLogin(value), {
    onSuccess: (res) => {
      if (res?.data?.status_code === 200) {
        // SuccessToast('Your request was successful');
      } else {
        console.log(res?.data?.message);

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.errors['0'] || err.message);
    },
  });
}

export function useRequestResetPassword(): any {
  return useMutation((value) => requestResetPassword(value), {
    onSuccess: (res) => {
      if (res?.data?.status_code === 200) {
        // SuccessToast('Your request was successful');
      } else {
        console.log(res?.data?.message);

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.errors['0'] || err.message);
    },
  });
}

export function useRequestDocumentUpload(): any {
  return useMutation((value) => requestDocumentUpload(value), {
    onSuccess: (res) => {
      if (res?.data?.status_code === 200) {
        // SuccessToast('Your request was successful');
      } else {
        console.log(res?.data?.message);

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.errors['0'] || err.message);
    },
  });
}

export function useRequestAPIkey(): any {
  return useMutation(() => requestAPIKey(), {
    onSuccess: (res) => {
      if (res?.data?.status_code === 200) {
        // SuccessToast('Your request was successful');
      } else {
        console.log(res?.data?.message);

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.errors['0'] || err.message);
    },
  });
}

export function useRequestGetAllPreFund(): any {
  return useMutation(() => requestGetAllPreFund(), {
    onSuccess: (res) => {
      if (res?.data?.status_code === 200) {
        // SuccessToast('Your request was successful');
      } else {
        console.log(res?.data?.message);

        ErrorHandler(res?.data?.message);
      }
    },
    onError: (err: any) => {
      console.log(err);

      ErrorHandler(err?.response?.data?.errors['0'] || err.message);
    },
  });
}
