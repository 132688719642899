import { ArrowRightOutlined, PaperClipOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Form, FormProps, Space, Upload, message } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useKycContext } from '../../../../../context/KycContext';
import { useAddQuestoonairre } from '../../../../../hooks/kyc/kyc.hook';
import { host } from '../../../../../services/constants/instance';
import { getLocalStorageData } from '../../../../../utils/src/formatCuurency';
import { StepProps } from '../../../../../utils/types';
import {
  CorporateData,
  DocumentCentre,
} from '../../../../../utils/verify_types';

const url =
  host === 'monibag.com'
    ? `https://apigateway.monibag.com/v2`
    : process.env.REACT_APP_GATEWAY_BASE_URL;
const token = sessionStorage.getItem('token');

export const DocumentCenter: React.FC<StepProps> = () => {
  const queryClient = useQueryClient();
  const { addQuestionairre } = useAddQuestoonairre();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { kycState, setIsSubmitting, setCounter, setDocumentCentre } =
    useKycContext();
  const [urls, setUrls] = useState({
    company_registration_document_url: '',
    company_profile_url: '',
    company_aml_cft_policy_url: '',
    company_kyc_policy_url: '',
    company_data_protection_policy_url: '',
    company_operating_license_url: '',
    company_customer_dispute_resolution_policy_url: '',
    company_information_security_policy_url: '',
    company_record_retention_policy_url: '',
  });

  const updateUrl = (key: keyof typeof urls, value: string) => {
    setUrls((prevUrls) => ({
      ...prevUrls,
      [key]: value,
    }));
  };

  const props: UploadProps = {
    name: 'document',
    maxCount: 1,
    action: `${url}/core/document/upload/save`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    showUploadList: true,
    listType: 'text',
    multiple: false,
  };

  const onFinish: FormProps<DocumentCentre>['onFinish'] = async (values) => {
    setIsSubmitting(true);
    if (values) {
      try {
        const CorporateInformation: any = getLocalStorageData(
          'CorporateInformation',
          {}
        );
        const BusinessActivity: any = getLocalStorageData(
          'BusinessActivity',
          {}
        );
        const OwnershipStructure: any = getLocalStorageData(
          'OwnershipStructureInfo',
          {}
        );
        const CompanyDirector: any = getLocalStorageData(
          'CompanyDirectorInfo',
          {}
        );
        const CorporateGovernance: any = getLocalStorageData(
          'CorporateGovernance',
          {}
        );
        const PolicyProcedure: any = getLocalStorageData('PolicyProcedure', {});
        const RiskAssessment: any = getLocalStorageData('RiskAssessment', {});
        const KycCdd: any = getLocalStorageData('KycCdd', {});
        const TransactionMonitoring: any = getLocalStorageData(
          'TransactionMonitoring',
          {}
        );
        const SanctionScreening: any = getLocalStorageData(
          'SanctionScreening',
          {}
        );
        const RecordRetention: any = getLocalStorageData('RecordRetention', {});
        const BusinessAudit: any = getLocalStorageData('BusinessAudit', {});
        const AmlTraining: any = getLocalStorageData('AmlTraining', {});
        const FatcaCompliance: any = getLocalStorageData('FatcaCompliance', {});

        const payload: CorporateData = {
          corporate_information: CorporateInformation
            ? { ...CorporateInformation }
            : { ...kycState.CorporateInformation },
          business_activity: BusinessActivity
            ? { ...BusinessActivity }
            : { ...kycState.BusinessActivity },
          ownership_structure: OwnershipStructure
            ? { ...OwnershipStructure }
            : { ...kycState.OwnershipStructure },
          company_director: CompanyDirector
            ? { ...CompanyDirector }
            : { ...kycState.CompanyDirector },
          corporate_governance: CorporateGovernance
            ? { ...CorporateGovernance }
            : { ...kycState.CorporateGovernance },
          policy_procedure: PolicyProcedure
            ? { ...PolicyProcedure }
            : { ...kycState.PolicyProcedure },
          risk_assessment: RiskAssessment
            ? { ...RiskAssessment }
            : { ...kycState.RiskAssessment },
          kyc_cdd: KycCdd ? { ...KycCdd } : { ...kycState.KycCdd },
          transaction_monitoring: TransactionMonitoring
            ? { ...TransactionMonitoring }
            : { ...kycState.TransactionMonitoring },
          sanction_screening: SanctionScreening
            ? { ...SanctionScreening }
            : { ...kycState.SanctionScreening },
          record_retention: RecordRetention
            ? { ...RecordRetention }
            : { ...kycState.RecordRetention },
          business_audit: BusinessAudit
            ? { ...BusinessAudit }
            : { ...kycState.BusinessAudit },
          aml_training: AmlTraining
            ? { ...AmlTraining }
            : { ...kycState.AmlTraining },
          fatca_compliance: FatcaCompliance
            ? { ...FatcaCompliance }
            : { ...kycState.FatcaCompliance },
          document_centre: { ...urls },
        };

        setCounter('increment');
        setDocumentCentre(values);
        localStorage.setItem('DocumentCentre', JSON.stringify(values));

        const response = await addQuestionairre.mutateAsync(payload);
        if (response.status === 200) {
          setIsSubmitting(false);
          await queryClient.refetchQueries();
          form.resetFields();
          navigate(`/developer-portal/home`, { replace: true });
        }
      } catch (error: any) {
        setIsSubmitting(false);
        return error;
      }
    }
  };

  const onFinishFailed: FormProps<DocumentCentre>['onFinishFailed'] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  const FieldsUrl = (watch: any) => {
    return (
      <a
        className='cursor-pointer'
        href={watch}
        target='_blank'
        rel='noreferrer'
      >
        <PaperClipOutlined />
        &nbsp;&nbsp;
        {watch}
      </a>
    );
  };

  useEffect(() => {
    const DocumentCentre: any = getLocalStorageData('DocumentCentre', {});
    if (DocumentCentre) {
      form.setFieldsValue({
        company_registration_document_url:
          DocumentCentre?.company_registration_document_url,
        company_profile_url: DocumentCentre?.company_profile_url,
        company_aml_cft_policy_url: DocumentCentre?.company_aml_cft_policy_url,
        company_kyc_policy_url: DocumentCentre?.company_kyc_policy_url,
        company_data_protection_policy_url:
          DocumentCentre?.company_data_protection_policy_url,
        company_operating_license_url:
          DocumentCentre?.company_operating_license_url,
        company_customer_dispute_resolution_policy_url:
          DocumentCentre?.company_customer_dispute_resolution_policy_url,
        company_information_security_policy_url:
          DocumentCentre?.company_information_security_policy_url,
        company_record_retention_policy_url:
          DocumentCentre?.company_record_retention_policy_url,
      });

      setUrls((prevUrls) => ({
        ...prevUrls,
        company_registration_document_url:
          DocumentCentre?.company_registration_document_url,
        company_profile_url: DocumentCentre?.company_profile_url,
        company_aml_cft_policy_url: DocumentCentre?.company_aml_cft_policy_url,
        company_kyc_policy_url: DocumentCentre?.company_kyc_policy_url,
        company_data_protection_policy_url:
          DocumentCentre?.company_data_protection_policy_url,
        company_operating_license_url:
          DocumentCentre?.company_operating_license_url,
        company_customer_dispute_resolution_policy_url:
          DocumentCentre?.company_customer_dispute_resolution_policy_url,
        company_information_security_policy_url:
          DocumentCentre?.company_information_security_policy_url,
        company_record_retention_policy_url:
          DocumentCentre?.company_record_retention_policy_url,
      }));
    }
  }, []);

  return (
    <Form
      name='signInForm'
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      layout='vertical'
      className='flex flex-col'
    >
      <p className='float-start place-self-start pb-7 text-BagText font-semibold'>
        Please upload all required documents for compliance verification{' '}
        <Link
          className='cursor-pointer border-b border-b-BagDarkLight pb-1 text-BagDarkLight font-Poppins text-base ps-3'
          to={`/developer-portal/home`}
        >
          Skip to portal <ArrowRightOutlined />
        </Link>
      </p>
      <div className='grid grid-cols-1 gap-y-5'>
        <Form.Item<DocumentCentre>
          name='company_registration_document_url'
          rules={[
            {
              required: true,
              message: 'Please upload company registration document',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            className='w-full float-start flex flex-col items-start justify-start space-y-1'
            {...props}
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue(
                    'company_registration_document_url',
                    response
                  );
                  updateUrl('company_registration_document_url', response);
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                Company Registration Documents (including directors’ details /
                means of identification)
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls?.company_registration_document_url &&
              FieldsUrl(urls?.company_registration_document_url)}
          </Upload>
        </Form.Item>

        <Form.Item<DocumentCentre>
          name='company_profile_url'
          rules={[
            {
              required: true,
              message: 'Please upload company profile',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            className='w-full float-start flex flex-col items-start justify-start space-y-1'
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue('company_profile_url', response);
                  updateUrl('company_profile_url', response);
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                Company Profile
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls?.company_profile_url && FieldsUrl(urls?.company_profile_url)}
          </Upload>
        </Form.Item>

        <Form.Item<DocumentCentre>
          name='company_aml_cft_policy_url'
          rules={[
            {
              required: true,
              message: 'Please upload aml/cft policy',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            className='w-full float-start flex flex-col items-start justify-start space-y-1'
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue('company_aml_cft_policy_url', response);
                  updateUrl('company_aml_cft_policy_url', response);
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                AML/CFT Policy Manual
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls.company_aml_cft_policy_url !== '' &&
              FieldsUrl(urls?.company_aml_cft_policy_url)}
          </Upload>
        </Form.Item>

        <Form.Item<DocumentCentre>
          name='company_kyc_policy_url'
          rules={[
            {
              required: true,
              message: 'Please upload company kyc policy',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            className='w-full float-col space-y-1 flex flex-row items-start justify-start'
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue('company_kyc_policy_url', response);
                  updateUrl('company_kyc_policy_url', response);
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                KYC Policy Manual
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls?.company_kyc_policy_url &&
              FieldsUrl(urls?.company_kyc_policy_url)}
          </Upload>
        </Form.Item>

        <Form.Item<DocumentCentre>
          name='company_data_protection_policy_url'
          rules={[
            {
              required: true,
              message: 'Please upload company data protection policy',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            className='w-full float-start flex flex-col space-y-1 items-start justify-start'
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue(
                    'company_data_protection_policy_url',
                    response
                  );
                  updateUrl('company_data_protection_policy_url', response);
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                Data Protection Policy
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls?.company_data_protection_policy_url &&
              FieldsUrl(urls?.company_data_protection_policy_url)}
          </Upload>
        </Form.Item>

        <Form.Item<DocumentCentre>
          name='company_operating_license_url'
          rules={[
            {
              required: true,
              message: 'Please upload operating license',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            className='w-full float-start flex flex-col space-y-1 items-start justify-start'
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue('company_operating_license_url', response);
                  updateUrl('company_operating_license_url', response);
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                Operating License
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls?.company_operating_license_url &&
              FieldsUrl(urls?.company_operating_license_url)}
          </Upload>
        </Form.Item>

        <Form.Item<DocumentCentre>
          name='company_customer_dispute_resolution_policy_url'
          rules={[
            {
              required: true,
              message: 'Please upload dispute resolution_policy',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            className='w-full float-start flex flex-col space-y-1 items-start justify-start'
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue(
                    'company_customer_dispute_resolution_policy_url',
                    response
                  );
                  updateUrl(
                    'company_customer_dispute_resolution_policy_url',
                    response
                  );
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                Customer Dispute Resolution Policy
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls?.company_customer_dispute_resolution_policy_url &&
              FieldsUrl(urls?.company_customer_dispute_resolution_policy_url)}
          </Upload>
        </Form.Item>

        <Form.Item<DocumentCentre>
          name='company_information_security_policy_url'
          rules={[
            {
              required: true,
              message: 'Please upload information security policy',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            className='w-full float-start flex flex-col space-y-1 items-start justify-start'
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue(
                    'company_information_security_policy_url',
                    response
                  );
                  updateUrl(
                    'company_information_security_policy_url',
                    response
                  );
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                Information Security Policy
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls?.company_information_security_policy_url &&
              FieldsUrl(urls?.company_information_security_policy_url)}
          </Upload>
        </Form.Item>

        <Form.Item<DocumentCentre>
          name='company_record_retention_policy_url'
          rules={[
            {
              required: true,
              message: 'Please upload record retention policy',
              type: 'url',
            },
          ]}
          className='w-full'
        >
          <Upload
            {...props}
            onChange={(info: UploadChangeParam<UploadFile<any>>) => {
              if (info.file.status !== 'uploading') {
              }
              if (info.file.status === 'done') {
                message.success(
                  `${info?.file?.name} file uploaded successfully`
                );
                const response = info?.file?.response?.data;
                if (response) {
                  form.setFieldValue(
                    'company_record_retention_policy_url',
                    response
                  );
                  updateUrl('company_record_retention_policy_url', response);
                }
              } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }}
            className='w-full float-start flex flex-col space-y-1 items-start justify-start'
          >
            <button
              className='flex flex-row items-center justify-between px-4 space-x-3 h-[50px] w-[1000px] border border-BagBorder rounded-md '
              style={{}}
            >
              <span className='font-Poppins font-normal text-BagNeutralDarkFoundation text-sm'>
                Document/Record Retention Policy
              </span>
              <span className='bg-BagDarkLight py-1 px-2 text-BagBorder font-Poppins text-sm font-normal'>
                Choose file
              </span>
            </button>
            {urls?.company_record_retention_policy_url &&
              FieldsUrl(urls?.company_record_retention_policy_url)}
          </Upload>
        </Form.Item>

        <Space
          size={'middle'}
          align='start'
          style={{ float: 'left' }}
          className='mt-3 flex flex-row items-center justify-start w-[1000px]'
        >
          <Form.Item>
            <Button
              type='default'
              htmlType='button'
              className='bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white'
              style={{ height: '56px', fontWeight: 'bold' }}
              onClick={() =>
                navigate(`/developer-portal/kyc/facta_compliance`, {})
              }
            >
              Back
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='small'
              className='bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation'
              style={{
                height: '56px',
                fontWeight: 'bold',
              }}
              loading={kycState.isSubmitting}
            >
              {kycState.isSubmitting ? 'Please wait...' : 'Submit'}
            </Button>
          </Form.Item>
        </Space>
      </div>
    </Form>
  );
};
