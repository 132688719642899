export const queryKeys = {
  user: 'user',
  mdaList: 'mdaList',
  invoiceRequest: 'invoiceRequest',
  onboardingList: 'onboardingList',
};

export const CUSTOMER_COUNTS = 'CUSTOMER_COUNTS';
export const AGENT_COUNTS = 'AGENT_COUNTS';
export const FUNDS_VALUE_BY_CURRENCY = 'FUNDS_VALUE_BY_CURRENCY';
export const FUNDS_VALUE_BY_BANK_EX_FUND = 'FUNDS_VALUE_BY_BANK_EX_FUND';
export const FUNDS_PER_BANK = 'FUNDS_PER_BANK';
export const TOP_FIVE_BANKS_PER_TRXN = 'TOP_FIVE_BANKS_PER_TRXN';
export const ALL_USERS = 'ALL_USERS';
export const FILTER_USERS_BY_ROLES = 'FILTER_USERS_BY_ROLES';
export const FILTER_ALL_USERS_BY_ROLE = 'FILTER_ALL_USERS_BY_ROLE';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_USER_QUESTIONAIRE = 'GET_USER_QUESTIONAIRE';

export const GET_USER_DETAILS_STATUS = 'GET_USER_DETAILS_STATUS';
export const GET_DOCUMENTS_REVIEW = 'GET_DOCUMENTS_REVIEW';
export const GET_ROLES_RIGHTS = 'GET_ROLES_RIGHTS';
export const GET_ALL_THRESHOLDS = 'GET_ALL_THRESHOLDS';
export const GET_ALL_AGENT_THRESHOLDS = 'GET_ALL_AGENT_THRESHOLDS';
export const GET_ALL_AGENT_PREFUND = 'GET_ALL_AGENT_PREFUND';
export const GET_ALL_BANK_PREFUND = 'GET_ALL_BANK_PREFUND';
export const GET_USER_COUNT = 'GET_USER_COUNT';
export const GET_ALL_PREFUNDBANKS_ENABLED = 'GET_ALL_PREFUNDBANKS_ENABLED';
export const GET_ALL_PREFUNDBANKS = 'GET_ALL_PREFUNDBANKS';
export const GET_ALL_BANKS = 'GET_ALL_BANKS';
export const GET_PREFUNDBANK = 'GET_PREFUNDBANK';
export const GET_PREFUNDBANK_BY_STATUS = 'GET_PREFUNDBANK_BY_STATUS';
export const GET_ALL_CURRENCY = 'GET_ALL_CURRENCY';
export const GET_ALL_CURRENCY_PAIR = 'GET_ALL_CURRENCY_PAIR';
export const GET_ALL_Entities = 'GET_ALL_Entities';
export const GET_ALL_ONBOARDING_RULES = 'GET_ALL_ONBOARDING_RULES';
export const GET_ALL_Secret_Questions = 'GET_ALL_Secret_Questions';

export const GET_ALL_PRE_FUNDS = 'GET_ALL_PRE_FUNDS';
export const GET_ALL_FUNDS = 'GET_ALL_FUNDS';
export const GET_ALL_AGENTS_FUNDS = 'GET_ALL_AGENTS_FUNDS';
export const GET_SINGLE_FUND = 'GET_SINGLE_FUND';
export const GET_SINGLE_AGENT_DETAILS = 'GET_SINGLE_AGENT_DETAILS';
export const GET_ALL_INSIGHTS_CURRENCY = 'GET_ALL_INSIGHTS_CURRENCY';
export const GET_THRESHOLD_BY_EMAIL_CURRENCY =
  'GET_THRESHOLD_BY_EMAIL_CURRENCY';
export const GET_EXCHANGE_RATE = 'GET_EXCHANGE_RATE';
export const GET_ALL_FEE_BAND = 'GET_ALL_FEE_BAND';
export const GET_ALL_FEE_BAND_BANK = 'GET_ALL_FEE_BAND_BANK';
export const GET_ALL_BANK_EXCHANGE_FUND = 'GET_ALL_BANK_EXCHANGE_FUND';
export const GET_ALL_BANK_EXCHANGE_FUND_2 = 'GET_ALL_BANK_EXCHANGE_FUND_2';
export const GET_ALL_BANK_EXCHANGE_FUND_3 = 'GET_ALL_BANK_EXCHANGE_FUND_3';

export const GET_ALL_BANK_FUND = 'GET_ALL_BANK_FUND';
export const GET_SINGLE_FUND_BY_CURRENCY = 'GET_SINGLE_FUND_BY_CURRENCY';
export const GET_ALL_ENABLED_PREFUNDBANKS = 'GET_ALL_ENABLED_PREFUNDBANKS';
export const GET_DEVELOPER_PROFILE = 'GET_DEVELOPER_PROFILE';
export const GET_USER_VERIFIED_STATUS = 'GET_USER_VERIFIED_STATUS';
export const GET_AGENT_ORDERS = 'GET_AGENT_ORDERS';
export const FILTER_AGENT_ORDERS = 'FILTER_AGENT_ORDERS';
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const GET_USER_PROFILE_DETAILS = 'GET_USER_PROFILE_DETAILS';
export const FETCH_ORDER_INIT = 'FETCH_INIT';
export const FETCH_ORDER_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_FAILURE';
export const FILTER_ORDER_BY_STATUS_DATE = 'FILTER_ORDER_BY_STATUS_DATE';
export const VERIFY_STEPS = 'VERIFY_STEPS';
export const GET_ONBOARDING_PROFILE_DETAILS = 'GET_ONBOARDING_PROFILE_DETAILS';
export const ADD_QUESTIONAIRE = 'ADD_QUESTIONAIRE';
export const UPDATE_QUESTIONAIRE = 'UPDATE_QUESTIONAIRE';
export const GET_QUESTIONAIRE = 'GET_QUESTIONAIRE';
export const GET_ROLE_RIGHTS = 'GET_ROLE_RIGHTS';
export const GET_API_KEY = 'GET_API_KEY';
