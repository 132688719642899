import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  check,
  home,
  humanLaptop,
  humanLock,
  humanPhone,
  humansSend,
  leftLayout,
  logo,
  rightLayout,
  rocketIcon,
  rocketLaunch,
  tellUsmoreIcon,
} from '../../../assets/images';
type Props = {
  children?: any;
};

const Layout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const features = [
    'Simple and fast remittance service',
    // "Multi-currency bank account to support our global customers",
    'Mobile digital wallet for fast and reliable payment for good and services',
  ];

  const [route, setRoute] = useState<string>('');
  const [headerText, setHeaderText] = useState<string>('');
  const [bottomImg, setBottomImage] = useState<any>(null);

  useEffect(() => {
    setRoute(location.pathname);
    switch (location.pathname) {
      case '/signin':
        setHeaderText('Welcome Back!');
        setBottomImage(rocketLaunch);
        break;

      case '/signup':
        setHeaderText('Sign Up');
        setBottomImage(rocketIcon);

        break;

      case '/verify-signup':
        setHeaderText('Verify Sign Up ');
        setBottomImage(rocketIcon);

        break;

      case '/forget-password':
        setHeaderText('Forgot Password');
        setBottomImage(humansSend);

        break;
      case '/new-password':
        setHeaderText('New Password');
        setBottomImage(humanLaptop);

        break;

      case '/otpVerify':
        setHeaderText('OTP Verification');
        setBottomImage(humanPhone);

        break;

      case '/createPin':
        setHeaderText('Create Your Transaction PIN');
        setBottomImage(humanLock);
        break;

      case '/tell_us_more':
        setHeaderText('Tell Us About You');
        setBottomImage(tellUsmoreIcon);
        break;

      default:
        setHeaderText('Welcome Back!');
        setBottomImage(rocketLaunch);
        break;
    }
  }, [location]);

  // tellUsmoreIcon

  const navigate = useNavigate();

  const redirectHome = () => {
    navigate('/home');
  };

  return (
    <div className='bg-danger'>
      <div className='bg-dark layout-dark-container '>
        <div className='d-flex justify-content-between position-absolute w-100 '>
          <div className='imgLeft cursor-pointer' onClick={redirectHome}>
            <img src={leftLayout} className='w-100 h-100' alt='' />
          </div>
          <div className='imgRight cursor-pointer' onClick={redirectHome}>
            <img src={rightLayout} className='w-100 h-100' alt='' />
          </div>
        </div>

        <div className='d-flex justify-content-between align-items-center header-logo'>
          <div className='logo cursor-pointer'>
            <a href='/'>
              <img src={logo} className='w-100 h-100' alt='' />
            </a>
          </div>

          <div className='home cursor-pointer'>
            <a href='/'>
              <img src={home} className='w-100 h-100' alt='' />
            </a>
          </div>
        </div>

        <div className='outletContent'>
          <div className='header-container d-none d-lg-block'>
            <div className='d-flex flex-column'>
              <span className='fs-2xx fw-bolder'>{headerText}</span>
              <div className='my-1 min-h-48px'>
                {route === '/signup' ||
                  (route === '/verify-signup' && (
                    <span className='fs-7 text-muted '>
                      FAST AND CONVENIENT WAY
                      <br /> TO
                      <span className='text-yellow fw-boldest'>
                        {' '}
                        SEND MONEY
                      </span>
                    </span>
                  ))}
              </div>
            </div>

            {route !== '/tell_us_more' && (
              <div>
                <div className='my-6'>
                  <span className='fs-2'>Bringing and keeping you closer</span>
                </div>

                <div>
                  {features.map((item, index) => {
                    return (
                      <div key={index} className='flex flex-row items-center'>
                        <div>
                          <img src={check} className='w-12px h-9px' alt='' />
                        </div>
                        <div className='ms-2'>
                          <span className='text-gray-800'>{item}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div
              className={
                bottomImg === rocketLaunch
                  ? 'bottom-content-left '
                  : 'bottom-content'
              }
            >
              <img src={bottomImg} className='w-450px h-431px' alt='' />
            </div>
          </div>

          <div className={route !== '/tell_us_more' ? 'main' : ''}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Layout };
