export interface IModal {
  show: boolean;
  onCancel: () => void;
  onDone?: any;
  data?: any;
  action?: any;
  isLoading?: boolean;
  status?: string;
  text?: string;
  checkedD?: any;
}

export enum IDENTITY_TYPES {
  PASSPORT = 'PASSPORT',
  NIN = 'NATIONAL_ID',
  DRIVER_LICENSE = 'DRIVING_LICENSE',
  VOTER_CARD = 'VOTER_ID',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  BUSINESS_REGISTRATION_ID = 'BUSINESS_REGISTRATION_ID',
  TAX_ID = 'TAX_ID',
}

export enum PAYMENT_METHOD {
  DEBIT_CARD = 'DEBIT_CARD',
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_TRANSFER = 'BANK_TRANSFER',
  CHEQUE = 'CHEQUE',
  CASH = 'CASH',
  MOBILE_WALLET = 'MOBILE_WALLET',
}

export enum ENTITY_TYPES {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export enum ORDER_EVENTS {
  ACCOUNT_PAYOUT = 'ACCOUNT_PAYOUT',
  CASH_PAYOUT = 'CASH_PAYOUT',
  WALLET_PAYOUT = 'WALLET_PAYOUT',
  ACCOUNT_ENQUIRY = 'ACCOUNT_ENQUIRY',
}

export enum REQUEST_METHOD {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum ROLS {
  DEFAULT = 'DEFAULT',
  ONBOARDING = 'ONBOARDING',
  ADMIN = 'ADMIN',
  USER = 'USER',
  SUPPORT = 'SUPPORT',
  AGENT = 'AGENT',
  BANK = 'BANK',
  COMPLIANCE = 'COMPLIANCE',
  SUPER_ADMIN = 'SUPER_ADMIN',
  VENDOR = 'VENDOR',
  FINANCE = 'FINANCE',
  DATA_STEWARD = 'DATA_STEWARD',
}

export enum CURRENCY {
  USD = 'USD',
  NGN = 'NGN',
  GBP = 'GBP',
}

export interface IIDENTITY {
  label: string;
  value: IDENTITY_TYPES;
}

export interface IIPAYMENTTYPES {
  label: string;
  value: PAYMENT_METHOD;
}

interface IOnboarding {
  created_by: string;
  created_time: string;
  is_verified: boolean;
  modified_by: string;
  modified_time: string;
  onboarding_status: string;
  require_verification: boolean;
  reviewed_by: string;
  reviewed_time: string;
}

export interface IUsers {
  email: string;
  email_verified: boolean;
  is_enabled: boolean;
  roles_rights: any;
  onboarding_profile: IOnboarding;
}

export interface IThreshold {
  agent_id: string;
  amount: string;
  created_by: string;
  created_time: string;
  currency_iso_3: string;
  email: string;
  modified_by: string;
  modified_time: string;
}

export interface IPrefundbanks {
  bank_id: string;
  bank_name: string;
  bank_address: string;
  bank_swift: string;
  bank_account_currency_iso_3: string;
  bank_account_number: string;
  bank_account_name: string;
  bank_account_iban_number: string;
  ordering_party: string;
  beneficiary: string;
  purpose: string;
  is_enabled: boolean;
  is_enabled_by: string;
  enabled_time: string;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
}

export interface IBanks {
  bank_id: string;
  country_iso_3: string;
  account_payout_enabled: Boolean;
  cash_payout_enabled: Boolean;
  wallet_payout_enabled: Boolean;
  account_enquiry_enabled: Boolean;
  bank_code: string;
  bank_name: string;
  bank_address: string;
  bank_swift: string;
  bank_account_currency_iso_3: string;
  bank_account_number: string;
  bank_account_name: string;
  bank_account_iban_number: string;
  ordering_party: string;
  beneficiary: string;
  purpose: string;
  is_enabled: boolean;
  is_enabled_by: string;
  enabled_time: string;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
  bank_nip_code: string;
}

export interface IEnabledPrefundBanks
  extends Pick<
    IPrefundbanks,
    | 'bank_id'
    | 'bank_name'
    | 'bank_account_currency_iso_3'
    | 'beneficiary'
    | 'purpose'
    | 'ordering_party'
    | 'bank_account_number'
  > {}

export interface IAddPrefundBanks {
  vostro_bank_id: string;
  vostro_bank_address: string;
  vostro_bank_swift: string;
  vostro_bank_sort_code: string;
  vostro_bank_aba: string;
  vostro_bank_routing_number: string;
  bank_id: string;
  bank_address: string;
  bank_swift: string;
  bank_account_currency_iso_3: string;
  bank_account_number: string;
  bank_account_name: string;
  bank_account_iban_number: string;
  ordering_party: string;
  beneficiary: string;
  purpose: string;
}

export interface IUpdatePrefundBanks extends Partial<IAddPrefundBanks> {}

export interface ICurrency {
  accountPayoutEnabled: boolean;
  canReceive: boolean;
  canSend: boolean;
  cashPayoutEnabled: boolean;
  countryIso3: string;
  createdBy: string;
  createdTime: string;
  currencyIso3: string;
  currencyName: string;
  currencyPrecision: number;
  modifiedBy: string;
  modifiedTime: string;
  walletPayoutEnabled: boolean;
}

export interface IPrefund {
  agent_id: string;
  email: string;
  reference_id: string;
  bank_id: string;
  bank_account_currency_iso_3: string;
  bank_account_number: string;
  amount: string;
  fund_date: string;
  is_verified: boolean;
  verified_by: string;
  verified_time: string;
  is_approved: boolean;
  approved_by: string;
  approved_time: string;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
  currency_iso_3?: string;
}

export interface IAddPrefund {
  reference_id: string;
  bank_id: string;
  bank_account_currency_iso_3: string;
  bank_account_number: string;
  amount: number | any;
  fund_date: any;
  email?: string;
  role?: ROLS;
}

export interface IUpdatePrefund extends Partial<IAddPrefund> {}

export interface IVerifyPrefund
  extends Pick<IAddPrefund, 'amount' | 'email' | 'reference_id'> {}

export interface IApprovePrefund {
  reference_id: string;
  email?: string;
}

export interface IFunds {
  agent_id: string;
  available_amount: string;
  bank_id: string;
  created_by: string;
  created_time: string;
  currency_iso_3: string;
  email: string;
  modified_by: string;
  modified_time: string;
  total_fund_amount: string;
  total_amount?: string;
}

export interface IAgentFund {
  available_amount: string;
  currency_iso_3: string;
  email: string;
  total_fee: string;
  total_fund_amount: string;
}

export interface IAddFeeBand {
  from_amount: number | string;
  to_amount: number | string;
  flat_fee: number | string;
  percentage_fee: number;
  cap_fee: number | any;
  currency_iso_3: string;
  email: string;
}

export interface IExchangeRate {
  exchange_rate_id: string;
  bank_id: string;
  base_currency_iso_3: string;
  term_currency_iso_3: string;
  buy_rate: string;
  sell_rate: string;
  rate_date: string;
  is_verified: boolean;
  verified_by: string;
  verified_time: string;
  is_approved: boolean;
  approved_by: string;
  approved_time: string;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
}

export interface IAddExchangeRate
  extends Pick<
    IExchangeRate,
    | 'bank_id'
    | 'base_currency_iso_3'
    | 'buy_rate'
    | 'sell_rate'
    | 'rate_date'
    | 'term_currency_iso_3'
  > {}

export interface IFeeBand {
  agent_id: string;
  email: string;
  band_id: string;
  from_amount: string;
  to_amount: string;
  flat_fee: string;
  percentage_fee: string;
  cap_fee: string;
  currency_iso_3: string;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
}

export interface ICurrencyConversion {
  sender_currency_iso_3?: string;
  receiver_currency_iso_3?: string;
  sender_amount?: number | any;
  receiver_amount?: number | any;
}

interface SenderCountry {
  sender_country_iso_3: string;
}

interface SenderIdentityIssuerCountry {
  sender_identity_issuer_country_iso_3: string;
}

interface SenderIdentity {
  sender_identity_type: string;
  sender_identity_issuer_country: SenderIdentityIssuerCountry;
  sender_identity_number: string;
  sender_identity_expiry_date?: string;
}

interface SenderCurrency {
  sender_currency_iso_3: string;
}

interface SenderSecret {
  sender_secret_question?: string;
  sender_secret_answer?: string;
}

interface ReceiverCountry {
  receiver_country_iso_3: string;
}

interface ReceiverIdentityIssuerCountry {
  receiver_identity_issuer_country_iso_3: string;
}

interface ReceiverIdentity {
  receiver_identity_type: string;
  receiver_identity_issuer_country: ReceiverIdentityIssuerCountry;
  receiver_identity_number: string;
  receiver_identity_expiry_date: string;
}

interface ReceiverBankCountry {
  receiver_bank_country_iso_3: string;
}

interface ReceiverBank {
  receiver_bank_id: string;
  receiver_bank_country: ReceiverBankCountry;
}

interface ReceiverCurrency {
  receiver_currency_iso_3: string;
}

export interface IAddOrder {
  event: string;
  customer_id: string;
  agent_order_id: string;
  sender: {
    sender_email: string;
    sender_date_of_birth?: string;
    sender_gender: string;
    sender_first_name?: string;
    sender_middle_name?: string;
    sender_last_name?: string;
    sender_business_name?: string;
    sender_address_line_1: string;
    sender_address_line_2: string;
    sender_city: string;
    sender_state: string;
    sender_country: SenderCountry;
    sender_phone_number: string;
    sender_identity: SenderIdentity;
    sender_currency: SenderCurrency;
    sender_amount: number | any;
    sender_reason: string;
    sender_secret?: SenderSecret;
    sender_occupation?: string;
    sender_relationship: string;
    sender_industry?: string;
    sender_payment_type: string;
    sender_entity_type: string;
    sender_post_zip_code?: string;
  };
  exchange_rate: number | any;
  receiver: {
    receiver_email: string;
    receiver_date_of_birth: string;
    receiver_gender: string;
    receiver_first_name: string;
    receiver_middle_name: string;
    receiver_last_name: string;
    receiver_business_name: string;
    receiver_address_line_1: string;
    receiver_address_line_2: string;
    receiver_city: string;
    receiver_state: string;
    receiver_country: ReceiverCountry;
    receiver_phone_number: string;
    receiver_identity?: ReceiverIdentity;
    receiver_bank: {
      receiver_bank_id: string;
      receiver_bank_country: {
        receiver_bank_country_iso_3: string;
      };
      receiver_bank_account_number: string;
    };
    receiver_currency: ReceiverCurrency;
    receiver_amount: number;
    receiver_occupation?: string;
    receiver_relationship: string;
    receiver_industry: string;
    receiver_entity_type: string;
    receiver_post_zip_code?: string;
  };
}

export interface IReceiver {
  receiver_email: string;
  receiver_date_of_birth: string;
  receiver_gender: string;
  receiver_first_name: string;
  receiver_middle_name: string;
  receiver_last_name: string;
  receiver_business_name: string;
  receiver_address_line_1: string;
  receiver_address_line_2: string;
  receiver_city: string;
  receiver_state: string;
  receiver_country: ReceiverCountry;
  receiver_phone_number: string;
  receiver_identity: ReceiverIdentity;
  receiver_bank: ReceiverBank;
  receiver_currency: ReceiverCurrency;
  receiver_amount?: number;
  receiver_occupation: string;
  receiver_relationship: string;
  receiver_industry: string;
  receiver_entity_type: string;
  agent_receiver_pep_status: string;
  agent_receiver_pep_reason: string;
  agent_receiver_compliance: boolean;
  agent_receiver_compliance_reason: string;
}

export interface ISender {
  sender_email: string;
  sender_date_of_birth: string;
  sender_gender: string;
  sender_first_name: string;
  sender_middle_name: string;
  sender_last_name: string;
  sender_business_name: string;
  sender_address_line_1: string;
  sender_address_line_2: string;
  sender_city: string;
  sender_state: string;
  sender_country: SenderCountry;
  sender_phone_number: string;
  sender_identity: SenderIdentity;
  sender_currency: SenderCurrency;
  sender_amount: number;
  sender_reason: string;
  sender_secret: SenderSecret;
  sender_occupation: string;
  sender_relationship: string;
  sender_industry: string;
  sender_payment_type: string;
  sender_entity_type: string;
  agent_sender_pep_status: string;
  agent_sender_pep_reason: string;
  agent_sender_compliance: boolean;
  agent_sender_compliance_reason: string;
}

export interface ISenderFormValues {
  sender_email: string;
  sender_business_name?: string | any;
  sender_first_name?: string | any;
  sender_last_name?: string | any;
  sender_middle_name?: string | any;
  sender_gender?: string | any;
  sender_date_of_birth?: string;
  sender_address_line_1: string;
  sender_address_line_2: string;
  sender_country_iso_3: string;
  sender_state: string;
  sender_city: string;
  sender_post_zip_code?: string | any;
  sender_currency_iso_3: string;
  sender_phone_number: string;
  sender_identity_type: string;
  sender_identity_issuer_country_iso_3: string;
  sender_identity_number: string;
  sender_identity_expiry_date?: string;
  sender_reason: string;
  sender_relationship: string;
  sender_industry: string;
  sender_payment_type: string;
  sender_entity_type: string;
  sender_secret_question?: string;
  sender_secret_answer?: string | any;
  agent_order_id: string;
  customer_id: string;
  event: string;
  prefix?: string | any;
  sender_occupation?: string | any;
}

export interface IReceiverFormValues {
  receiver_email: string;
  receiver_business_name?: string | any;
  receiver_first_name?: string | any;
  receiver_last_name?: string | any;
  receiver_middle_name?: string | any;
  receiver_gender?: string | any;
  receiver_date_of_birth?: string | any;
  receiver_country_iso_3: string;
  receiver_state: string;
  receiver_city: string;
  receiver_address_line_1: string;
  receiver_address_line_2: string;
  receiver_phone_number: string;
  receiver_identity_type: string;
  receiver_identity_issuer_country: string;
  receiver_identity_number: string;
  receiver_identity_expiry_date: string;
  receiver_bank_id: string;
  receiver_bank_country_iso_3: string;
  receiver_bank_account_number: string;
  receiver_currency_iso_3: string;
  receiver_relationship: string;
  receiver_occupation: string;
  receiver_industry: string;
  receiver_entity_type: string;
  prefix?: string;
  receiver_post_zip_code?: string;
}

export interface IAmountFormValues {
  receiver_amount: string;
  sender_amount: string;
  exchange_rate: string;
}

interface Currency {
  country_iso_3: string;
  currency_iso_3: string;
  currency_name: string;
  currency_precision: number;
  can_send: boolean;
  can_receive: boolean;
  account_payout_enabled: boolean;
  wallet_payout_enabled: boolean;
  cash_payout_enabled: boolean;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
}

export interface Country {
  country_name: string;
  country_iso_2: string;
  country_iso_3: string;
  currencies: Currency[];
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
}

export interface IDialCodes {
  name: string;
  dial_code: string;
  code: string;
}

export interface IPerson {
  first_name: string;
  middle_name: string;
  last_name: string;
  business_name: string;
  entity_type: string;
  phone_number: string;
  date_of_birth: string;
  gender: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  country: string;
  post_zip_code: string;
  image_url: string;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
}

export interface IUpdatePerson
  extends Partial<
    Omit<
      IPerson,
      | 'image_url'
      | 'created_time'
      | 'created_by'
      | 'modified_time'
      | 'modified_by'
    >
  > {
  apiKey?: string;
  token?: string;
}

export interface IPersons
  extends Omit<
    IPerson,
    | 'first_name'
    | 'last_name'
    | 'date_of_birth'
    | 'gender'
    | 'middle_name'
    | 'phone_number'
    | 'business_name'
    | 'entity_type'
  > {}

export interface StepProps {
  handleNextStep?: () => void | undefined;
  COUNTRY_JSON?: Country[];
}

export type ProvidersType = [React.ElementType, Record<string, unknown>];
export type ChildrenType = {
  children: Array<React.ElementType>;
};

export interface IOnboardingProfile {
  created_by: string;
  created_time: string;
  is_verified: boolean;
  modified_by: string;
  modified_time: string;
  onboarding_status: string;
  require_verification: boolean;
  reviewed_by: string;
  reviewed_time: string;
}

export interface IUserProfile {
  email: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  business_name: string;
  entity_type: string;
  phone_number: string;
  date_of_birth: string;
  gender: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  country: string;
  post_zip_code: string;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
  image_url: string;
}

export interface PrefundBankDetails {
  bank_id: string;
  bank_name: string;
  bank_address: string;
  bank_swift: string;
  bank_account_currency_iso_3: string;
  bank_account_number: string;
  bank_account_name: string;
  ordering_party: string;
  beneficiary: string;
  purpose: string;
  is_enabled: boolean;
  is_enabled_by: string;
  enabled_time: string;
  created_time: string;
  created_by: string;
  modified_time: string;
  modified_by: string;
}
