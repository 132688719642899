import { ErrorToast } from './toast/index';
import { clearStoredUser } from './user-storage';

export function ErrorHandler(err: any) {
  //const queryClient = useQueryClient();

  ErrorToast(err);

  console.log(err);

  if (err.response) {
    if (err.response.status === 401) {
      //Handle the unauthorized error for this specific query
      // clearStoredUser();
      //queryClient.setQueriesData(queryKeys.user, null);
      // window.location.href = "/auth/signin";
      ErrorToast(err.response?.data?.message || 'Please login to continue');
    }
    if (err.response.status === 417) {
      ErrorToast(err.response?.data?.message);
    }
    if (err.response.status === 402 || err.response.status === 405) {
      ErrorToast(err.response?.data?.message || err.response.data.detail);
    }
    if (err.response.status === 400) {
      ErrorToast(err);
    }
    if (err.response.status === 404) {
      ErrorToast(err?.response?.data?.title || err?.response?.data?.message);
    }
    if (
      err.response.status === 503 ||
      err.response.status === 501 ||
      err.response.status === 504 ||
      err.response.status === 405 ||
      err.response.status === 500 ||
      err.response.status === 403
    ) {
      ErrorToast(
        err.response?.data?.message ||
          'Error in network connection, try later...'
      );
    } else {
      ErrorToast(err.response?.data?.message || 'Internal server error');
    }
  } else {
    ErrorToast(err.response?.data?.message || err || 'Network Error!');
  }
}
