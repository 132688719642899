import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { backIcon } from '../../assets/images';
import TimerComponent from './timer';

interface PinProps {
  proceed: (e: any) => void;
  header: string;
  message: string;
  numOfInputs?: number;
  canResend: boolean;
  resendFn?: any;
  resendText?: string;
  doneText?: string;
  timeRemaining?: any;
  loading?: boolean;
  resend?: boolean;
}
const Pin: React.FC<PinProps> = ({
  proceed,
  header,
  message,
  numOfInputs,
  canResend,
  resendFn,
  resendText,
  doneText,
  timeRemaining,
  loading,
  resend,
}) => {
  const [otp, setOtp] = useState<string>('');
  const navigate = useNavigate();

  const location = useLocation();

  var loc = sessionStorage.getItem('location');

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className='main-container p-7 mt-5'>
      <div>
        <div className='d-flex align-items-center my-6 ms-3r'>
          <img
            src={backIcon}
            alt=''
            className='w-30px h-30px'
            onClick={() => goBack()}
          />

          <div className='ms-5'>
            <span className='fs-2xx fw-bolder text-dblack '>{header}</span>
          </div>
        </div>

        <div className='otp-container'>
          <div>
            <span className='enter-otp'>{message}</span>
          </div>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={numOfInputs || 6}
            renderSeparator={<span>-</span>}
            renderInput={(props: any) => <input {...props} />}
            containerStyle={'otp-box mt-5'}
            inputStyle={'w-100'}
          />

          {/* {timeRemaining.seconds && (
            <div onClick={() => resendFn()} style={{ marginTop: "1rem" }}>
              <span>
                <div>
                  <p>
                    {" "}
                    <span style={{ color: "black" }}>Resend OTP in </span>{" "}
                    <span className="resend-text">
                      {timeRemaining.seconds}s
                    </span>
                  </p>
                </div>
              </span>
            </div>
          )} */}

          <div>
            <Button
              htmlType='submit'
              disabled={otp.length !== 6 || loading === true}
              className='done-btn'
              onClick={() => proceed(otp)}
            >
              {loading ? (
                <span className='indicator-progress d-flex justify-content-center  align-items-center'>
                  <span className='d-none d-md-block'>Please wait... </span>
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <span>{doneText || 'Done'}</span>
              )}
            </Button>
          </div>

          {/* {canResend && loc !== "/verify-signup" && (
            <>
              <button
                style={{ border: "none", backgroundColor: "transparent" }}
                onClick={() => resendFn()}
                disabled={timeRemaining.seconds > 0}
              >
                {resend ? (
                  <span
                    className={
                      timeRemaining.seconds > 0
                        ? "resent-text-inactive"
                        : "resend-text"
                    }
                  >
                    <span>Resending...</span>
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                ) : (
                  <span
                    className={
                      timeRemaining.seconds > 0
                        ? "resent-text-inactive"
                        : "resend-text"
                    }
                  >
                    {resendText || "Resend OTP"}
                  </span>
                )}
              </button>
            </>
          )} */}
          <TimerComponent resend={() => resendFn()} />
        </div>
      </div>
    </div>
  );
};

export { Pin };
