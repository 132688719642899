import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Form, FormProps, Input, Select, Space, StepProps } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKycContext } from '../../../../../context/KycContext';
import { RiskLevels, YES_NO } from '../../../../../utils/data';
import { getLocalStorageData } from '../../../../../utils/src/formatCuurency';
import { RiskAssessment } from '../../../../../utils/verify_types';

export const StepSix: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setRefetch,
    setRiskAssessment,
  } = useKycContext();

  const exist_prohibit_ml_risk_customer = Form.useWatch(
    'exist_prohibit_ml_risk_customer',
    form
  );

  const is_service_provided_selected_industry = Form.useWatch(
    'is_service_provided_selected_industry',
    form
  );

  const onFinish: FormProps<any>['onFinish'] = (values: any) => {
    if (values) {
      let payload: any = { ...values };
      if (payload?.is_service_provided_selected_industry === true) {
        const selected_industry_risk_level = {
          on_line_gaming_firms: values?.on_line_gaming_firms,
          foreign_shell_banks: values?.foreign_shell_banks,
          pornography_red_light: values?.pornography_red_light,
          payable_through_accounts: values?.payable_through_accounts,
          bitcoin_or_other_virtual_currency_dealers:
            values?.bitcoin_or_other_virtual_currency_dealers,
          charities: values?.charities,
          gem_dealers_precious_metal_dealers_travel_and_tour_companies:
            values?.gem_dealers_precious_metal_dealers_travel_and_tour_companies,
          embassy_consulate_military_attache:
            values?.embassy_consulate_military_attache,
          gambling_lottery_businesses: values?.gambling_lottery_businesses,
          used_car_dealers: values?.used_car_dealers,
          nested_downstream_correspondent_banks:
            values?.nested_downstream_correspondent_banks,
        };
        payload.selected_industry_risk_level =
          payload?.is_service_provided_selected_industry === true
            ? selected_industry_risk_level
            : {};
      }

      if (payload?.is_service_provided_selected_industry === false) {
        delete values?.on_line_gaming_firms;
        delete values?.foreign_shell_banks;
        delete values?.pornography_red_light;
        delete values?.payable_through_accounts;
        delete values?.bitcoin_or_other_virtual_currency_dealers;
        delete values?.charities;
        delete values?.gem_dealers_precious_metal_dealers_travel_and_tour_companies;
        delete values?.embassy_consulate_military_attache;
        delete values?.gambling_lottery_businesses;
        delete values?.used_car_dealers;
        delete values?.nested_downstream_correspondent_banks;

        delete payload?.on_line_gaming_firms;
        delete payload?.foreign_shell_banks;
        delete payload?.pornography_red_light;
        delete payload?.payable_through_accounts;
        delete payload?.bitcoin_or_other_virtual_currency_dealers;
        delete payload?.charities;
        delete payload?.gem_dealers_precious_metal_dealers_travel_and_tour_companies;
        delete payload?.embassy_consulate_military_attache;
        delete payload?.gambling_lottery_businesses;
        delete payload?.used_car_dealers;
        delete payload?.nested_downstream_correspondent_banks;
      }
      setRiskAssessment(payload);
      localStorage.setItem('RiskAssessment', JSON.stringify(values));
      setRefetch();
      setIsSubmitting(false);
      setCounter('increment');
      navigate(
        `/developer-portal/kyc/know_your_customer_and_customer_due_diligence`,
        {}
      );
    }
  };

  const onFinishFailed: FormProps<RiskAssessment>['onFinishFailed'] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const RiskAssessment: any = getLocalStorageData('RiskAssessment', {});
    if (RiskAssessment) {
      form.setFieldsValue({
        exist_ml_risk_classification:
          RiskAssessment?.exist_ml_risk_classification,
        is_ml_risk_classification_used:
          RiskAssessment?.is_ml_risk_classification_used,
        exist_prohibit_ml_risk_customer:
          RiskAssessment?.exist_prohibit_ml_risk_customer,
        prohibit_ml_risk_customer_list:
          RiskAssessment?.prohibit_ml_risk_customer_list,
        high_risk_business_locations:
          RiskAssessment?.high_risk_business_locations,
        is_enhanced_due_diligence_pep_high_risk_customer:
          RiskAssessment?.is_enhanced_due_diligence_pep_high_risk_customer,
        pep_approver: RiskAssessment?.pep_approver,
        is_service_provided_selected_industry:
          RiskAssessment?.is_service_provided_selected_industry,
      });
      if (RiskAssessment?.is_service_provided_selected_industry === true) {
        form.setFieldsValue({
          on_line_gaming_firms:
            RiskAssessment?.selected_industry_risk_level?.on_line_gaming_firms,
          foreign_shell_banks:
            RiskAssessment?.selected_industry_risk_level?.foreign_shell_banks,
          pornography_red_light:
            RiskAssessment?.selected_industry_risk_level?.pornography_red_light,
          payable_through_accounts:
            RiskAssessment?.selected_industry_risk_level
              ?.payable_through_accounts,
          bitcoin_or_other_virtual_currency_dealers:
            RiskAssessment?.selected_industry_risk_level
              ?.bitcoin_or_other_virtual_currency_dealers,
          charities: RiskAssessment?.selected_industry_risk_level?.charities,
          gem_dealers_precious_metal_dealers_travel_and_tour_companies:
            RiskAssessment?.selected_industry_risk_level
              ?.gem_dealers_precious_metal_dealers_travel_and_tour_companies,
          embassy_consulate_military_attache:
            RiskAssessment?.selected_industry_risk_level
              ?.embassy_consulate_military_attache,
          gambling_lottery_businesses:
            RiskAssessment?.selected_industry_risk_level
              ?.gambling_lottery_businesses,
          used_car_dealers:
            RiskAssessment?.selected_industry_risk_level?.used_car_dealers,
          nested_downstream_correspondent_banks:
            RiskAssessment?.selected_industry_risk_level
              ?.nested_downstream_correspondent_banks,
        });
      }
    }
  }, []);

  return (
    <>
      <Form
        form={form}
        name='RiskAssessment'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
      >
        <div className='grid grid-cols-2 gap-x-8 gap-y-0 pb-2 w-full'>
          <Form.Item<RiskAssessment>
            name='exist_ml_risk_classification'
            label='Does the institution have a ML/FT 
risk classification system in effect?'
            rules={[
              {
                required: true,
                message: 'Please select a value',
              },
            ]}
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='is_ml_risk_classification_used'
            label='Does the institution have a ML/FT 
risk classification system in effect?'
            rules={[
              {
                required: true,
                message: 'Please select a value',
              },
            ]}
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='exist_prohibit_ml_risk_customer'
            label='Are there customers that are prohibited from doing business with the institution based on risk of ML/FT?'
            rules={[
              {
                required: true,
                message: 'Please select a value',
              },
            ]}
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>
        </div>

        {exist_prohibit_ml_risk_customer === true && (
          <Form.Item
            className=''
            label='If so, which ones?'
            rules={[
              {
                required: exist_prohibit_ml_risk_customer === true,
                message: 'Please input customer list',
              },
            ]}
          >
            {/* <Form.Item<RiskAssessment>
              name="prohibit_ml_risk_customer_list"
              label="If so, which ones?"
              rules={[
                {
                  required: exist_prohibit_ml_risk_customer === true,
                  message: "Please input customer list",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item> */}

            <Form.List
              name='prohibit_ml_risk_customer_list'
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error('At least one customer'));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <div className='grid grid-cols-1'>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              "Please input customer's name or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder=''
                          size='large'
                          style={{ width: '65%' }}
                        />
                      </Form.Item>
                      <Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className='dynamic-delete-button'
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <button
                      type='button'
                      onClick={() => add()}
                      className='border border-dashed border-black rounded-md py-3'
                      style={{ width: '30%' }}
                      // icon={<PlusOutlined />}
                    >
                      Add field
                    </button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </Form.Item>
        )}

        <Form.Item
          className=''
          label='Kindly identify those high-risk locations where the institution conducts business?'
          rules={[
            {
              required: true,
              message: 'Please input high risk locations',
            },
          ]}
        >
          {/* <Form.Item
              name={"high_risk_business_locations"}
              label="Kindly identify those high-risk locations where the institution conducts business?"
              className="w-2/3"
              rules={[
                {
                  required: true,
                  message: "Please input high risk locations",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item> */}

          <Form.List
            name='high_risk_business_locations'
            rules={[
              {
                validator: async (_, names) => {
                  if (!names || names.length < 1) {
                    return Promise.reject(new Error('At least one location'));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <div className='grid grid-cols-1'>
                {fields.map((field, index) => (
                  <Form.Item required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please input location.',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder=''
                        size='large'
                        style={{ width: '65%' }}
                      />
                    </Form.Item>
                    <Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  </Form.Item>
                ))}
                <Form.Item>
                  <button
                    type='button'
                    onClick={() => add()}
                    className='border border-dashed border-black rounded-md py-3'
                    style={{ width: '30%' }}
                    // icon={<PlusOutlined />}
                  >
                    Add field
                  </button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form.Item>

        <div className='grid grid-cols-2 gap-x-8 pb-2'>
          <Form.Item
            name='is_enhanced_due_diligence_pep_high_risk_customer'
            label='Are PEPs and high-risk clients subject to enhanced due diligence?'
            rules={[
              {
                required: true,
                message: 'Please select a value',
              },
            ]}
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name={'pep_approver'}
            label='Who approves the establishment of relationship with PEPs?'
            rules={[
              {
                required: true,
                message: 'Please input approver',
              },
            ]}
          >
            <Input size='large' placeholder='' />
          </Form.Item>
        </div>

        <Form.Item
          name='is_service_provided_selected_industry'
          label='Do you provide financial services for the following industries?'
          className='w-2/3'
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Select
            placeholder=''
            options={[...YES_NO]}
            size='large'
            className='text-left'
          />
        </Form.Item>

        <div className='grid grid-cols-2 gap-x-8 gap-y-0 pb-2 w-full'>
          <Form.Item
            name='nested_downstream_correspondent_banks'
            label='Nested/Downstream Correspondent Banks'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='on_line_gaming_firms'
            label='On-line gaming firms'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='foreign_shell_banks'
            label='Foreign Shell banks'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='pornography_red_light'
            label='Pornography (Red light)'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='payable_through_accounts'
            label='Payable through accounts'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='bitcoin_or_other_virtual_currency_dealers'
            label='Bitcoin or other virtual currency dealers'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='charities'
            label='Charities'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='gem_dealers_precious_metal_dealers_travel_and_tour_companies'
            label='Gem dealers/Precious Metal Dealers Travel and tour companies'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='embassy_consulate_military_attache'
            label='Embassy/Consulate/Military attaché'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='gambling_lottery_businesses'
            label='Gambling/Lottery businesses'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='used_car_dealers'
            label='Used car dealers'
            rules={[
              {
                required: is_service_provided_selected_industry === true,
                message: 'Please select a risk level',
              },
            ]}
          >
            <Select
              placeholder='Risk level'
              options={[...RiskLevels]}
              size='large'
              className='text-left'
            />
          </Form.Item>
        </div>

        <Space
          size={'middle'}
          align='baseline'
          style={{ float: 'left' }}
          className='mt-10'
        >
          <Form.Item>
            <Button
              type='default'
              htmlType='button'
              className='bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white'
              style={{ height: '56px', fontWeight: 'bold' }}
              onClick={() =>
                navigate(
                  `/developer-portal/kyc/policies_practicies_and_procedures`
                )
              }
            >
              Back
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='small'
              className='bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation'
              style={{
                height: '56px',
                fontWeight: 'bold',
              }}
            >
              Save and Continue
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </>
  );
};
