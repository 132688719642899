import { Button, Form, FormProps, Input, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { useKycContext } from '../../../../../context/KycContext';
import { YES_NO } from '../../../../../utils/data';
import { getLocalStorageData } from '../../../../../utils/src/formatCuurency';
import { StepProps } from '../../../../../utils/types';
import { CorporateGovernance } from '../../../../../utils/verify_types';

export const StepFour: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState('');
  const navigate = useNavigate();

  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setCorporateGovernance,
    setRefetch,
  } = useKycContext();

  const onFinish: FormProps<CorporateGovernance>['onFinish'] = (values) => {
    if (values) {
      const compliance_officer_phone_number: string = phone.startsWith('+')
        ? '+' + phone.replace(/^\++/, '')
        : '+' + phone;
      const payload = {
        ...values,
        compliance_officer_phone_number,
      };
      setCorporateGovernance(payload);
      localStorage.setItem('CorporateGovernance', JSON.stringify(payload));
      setRefetch();
      setIsSubmitting(false);
      setCounter('increment');
      navigate('/developer-portal/kyc/policies_practicies_and_procedures', {});
    }
  };

  const onFinishFailed: FormProps<CorporateGovernance>['onFinishFailed'] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const CorporateGovernance: any = getLocalStorageData(
      'CorporateGovernance',
      {}
    );

    if (CorporateGovernance) {
      form.setFieldsValue({
        exist_established_aml: CorporateGovernance?.exist_established_aml,
        compliance_officer_name: CorporateGovernance?.compliance_officer_name,
        compliance_officer_email: CorporateGovernance?.compliance_officer_email,
        compliance_officer_phone_number:
          CorporateGovernance?.compliance_officer_phone_number,
        exist_adequate_non_compliance_sanction:
          CorporateGovernance?.exist_adequate_non_compliance_sanction,
        compliance_regulator_name:
          CorporateGovernance?.compliance_regulator_name,
      });
      if (CorporateGovernance?.compliance_officer_phone_number) {
        setPhone(CorporateGovernance?.compliance_officer_phone_number);
      }
    }
  }, []);

  return (
    <>
      <Form
        form={form}
        name='CorporateGovernance'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        layout='vertical'
      >
        <div className='grid grid-cols-1 gap-x-8 gap-y-0 pb-2 w-full'>
          <Form.Item
            name='exist_established_aml'
            label='Has the Board of Directors established an AML/CFT program?'
            className='w-2/3'
            rules={[
              {
                required: true,
                message: 'Please select an option',
              },
            ]}
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            label={
              <div className='flex flex-col space-y-2'>
                <span>
                  Has the institution appointed an AML/CFT compliance officer?
                </span>
                <span>
                  {' '}
                  If Yes, please provide the name, Phone no. and email{' '}
                </span>
              </div>
            }
            className='w-full '
          >
            <div className='grid grid-cols-3 gap-x-5 pt-3'>
              <Form.Item
                name={'compliance_officer_name'}
                rules={[
                  {
                    required: true,
                    message: 'Please input complaince officer name',
                  },
                  {
                    type: 'string',
                    pattern: /^[a-zA-Z0-9\s-]*$/,
                    message: 'Name must not contain special characters',
                  },
                ]}
              >
                <Input size='large' placeholder='Name' />
              </Form.Item>

              <Form.Item
                name={'compliance_officer_email'}
                rules={[
                  {
                    required: true,
                    message: 'Please input complaince officer Email',
                  },
                  {
                    type: 'email',
                    message: 'Email is not a valid Email',
                    pattern:
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  },
                ]}
              >
                <Input size='large' placeholder='Email' />
              </Form.Item>

              <Form.Item>
                <PhoneInput
                  country={'ng'}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  inputClass=''
                  containerClass=''
                  inputStyle={{
                    background: '',
                    borderRadius: '8px',
                    height: 50,
                    width: '100%',
                    color: '#000',
                    fontFamily: 'Poppins',
                  }}
                  containerStyle={{
                    background: '',
                    borderRadius: '8px',
                    height: 50,
                    width: '100%',
                    color: '#000',
                    fontFamily: 'Poppins',
                  }}
                />
                <small className='hidden'>
                  Please input compliance officer phone number
                </small>
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item
            name='exist_adequate_non_compliance_sanction'
            label='Does your institution have appropriate and adequate administrative sanctions in place for non-compliance with AML obligations?'
            className='w-2/3'
            rules={[
              {
                required: true,
                message: 'Please select an option',
              },
            ]}
          >
            <Select
              placeholder=''
              options={[...YES_NO]}
              size='large'
              className='text-left'
            />
          </Form.Item>

          <Form.Item
            name='compliance_regulator_name'
            label='Please provide the name of the Regulator that oversees the AML/CFT compliance of the Institution.'
            className='w-2/3'
            rules={[
              {
                required: true,
                message: 'Please input regulator institution',
              },
            ]}
          >
            <Input size='large' />
          </Form.Item>
        </div>

        <Space
          size={'middle'}
          align='baseline'
          style={{ float: 'left' }}
          className='mt-10'
        >
          <Form.Item>
            <Button
              type='default'
              htmlType='button'
              className='bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white'
              style={{ height: '56px', fontWeight: 'bold' }}
              onClick={() =>
                navigate(`/developer-portal/kyc/ownership_structure`, {})
              }
            >
              Back
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='small'
              className='bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation'
              style={{
                height: '56px',
                fontWeight: 'bold',
              }}
              loading={kycState.isSubmitting}
            >
              Save and Continue
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </>
  );
};
